<template>
  <div
    class="flex items-center justify-center font-medium text-white"
    :style="{
      backgroundColor: colour,
    }"
  >
    {{ initials }}
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseAvatarPlaceholder",

  props: {
    /** The name of the avatar object */
    name: {
      type: String,
      required: true,
    },
  },

  setup() {
    return {
      colours: [
        "#1abc9c",
        "#2ecc71",
        "#9b59b6",
        "#34495e",
        "#16a085",
        "#27ae60",
        "#8e44ad",
        "#e74c3c",
        "#95a5a6",
        "#d35400",
        "#c0392b",
        "#bdc3c7",
        "#7f8c8d",
      ],
    };
  },

  computed: {
    colour() {
      const charIndex = this.initials.charCodeAt(0) - 65;
      const colourIndex = charIndex % this.colours.length;
      return this.colours[colourIndex];
    },

    initials() {
      /* istanbul ignore next -- @preserve */
      return (
        this.name
          .match(/(\b\S)?/g)
          ?.join("")
          .match(/(^\S|\S$)?/g)
          ?.join("")
          .toLocaleUpperCase() ?? ""
      );
    },
  },
});
</script>

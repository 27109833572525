<template>
  <div class="border rounded-xl">
    <div class="flex flex-col gap-2 sm:gap-4 sm:flex-row justify-between sm:items-center py-6 px-4 sm:px-6 border-b">
      <div>
        <h2 class="text-base font-semibold leading-7 text-gray-900">{{ title }}</h2>

        <span v-if="description" class="text-sm text-gray-500">{{ description }}</span>
      </div>

      <div class="flex-shrink-0">
        <slot name="pageButtonBar"> </slot>
      </div>
    </div>

    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseCardForm",

  props: {
    /** The title of the card */
    title: {
      required: true,
      type: String,
    },

    /** The description of the card */
    description: {
      required: false,
      type: String,
      default: undefined,
    },
  },
});
</script>

<template>
  <label :for="id" class="block" data-testid="text-label" :class="labelClass">
    <span
      data-testid="text-label-text"
      v-bind="{
        ...(hideLabel ? { class: 'sr-only' } : {}),
      }"
    >
      {{ label }}
    </span>

    <div class="relative" :class="{ 'mt-2': !hideLabel }">
      <input class="rounded-md shadow-sm" v-bind="attributes" @input="handleInput($event)" />
    </div>
  </label>
  <!-- Error message -->
  <p
    v-show="hasErrorMessage"
    :id="errorId"
    :aria-hidden="!hasErrorMessage"
    class="mt-2 font-normal text-sm text-red-600"
  >
    {{ errorMessage }}
  </p>
</template>

<script lang="ts">
import useModelValue from "@/base/composables/ModelValueComposable.ts";
import assertStringIsNotBlank from "@/base/functions/asserts/strings/AssertStringIsNotBlank.ts";
import type { DebouncedFunc } from "lodash";
import { debounce } from "lodash";
import type { PropType } from "vue";
import { defineComponent } from "vue";

/**
 * Password input with label
 * @author Jamie Wood
 */
export default defineComponent({
  name: "BaseInputText",

  inheritAttrs: false,

  // Prevents inherited attributes so we can control binding

  props: {
    /**
     * Id HTML attribute
     */
    id: {
      required: true,
      type: String,
      validator: (id) => assertStringIsNotBlank(id),
    },

    /**
     * Input label text
     */
    label: {
      required: true,
      type: String as PropType<string>,
      validator: (label) => assertStringIsNotBlank(label),
    },

    /**
     * Whether to hide the label
     */
    hideLabel: {
      required: false,
      type: Boolean,
    },

    /**
     * Placeholder HTML attribute for input placeholder text
     */
    placeholder: {
      required: false,
      type: String as PropType<string | undefined>,
      default: undefined,
    },

    /**
     * HTML attribute to prevent browser interaction
     */
    disabled: {
      required: false,
      type: Boolean,
    },

    /**
     * Model value prop for v-model two-way binding
     */
    modelValue: {
      required: false,
      type: String as PropType<string | null | undefined>,
      default: undefined,
    },

    /**
     * Error message to render with the input
     *
     * Should only be used if there is a error to display
     */
    errorMessage: {
      required: false,
      type: String as PropType<string | undefined>,
      default: undefined,
    },

    /**
     * Id HTML attribute for error message
     */
    errorId: {
      required: false,
      type: String,
      default: "error",
      validator: (id) => assertStringIsNotBlank(id),
    },

    /** Debounce time in milliseconds */
    debounce: {
      required: false,
      type: Number as PropType<number | undefined>,
      default: 0,
      validator: (val: number) => val >= 0,
    },

    /**
     * The maximum length of the input string.
     */
    maxLength: {
      type: [Number, null, undefined] as PropType<number | null | undefined>,
      required: false,
      default: undefined,
    },

    /**
     * Class for the field label.
     */
    labelClass: {
      type: String,
      required: false,
      default: "text-sm font-medium text-gray-700",
    },
  },

  emits: {
    "update:modelValue": (input: string) => {
      return typeof input === "string";
    },
  },

  setup() {
    const { updateModelValue } = useModelValue();
    return {
      updateModelValue,
    };
  },

  data() {
    return {
      debounceFunction: undefined as DebouncedFunc<(event: Event) => void> | undefined,
    };
  },

  computed: {
    attributes() {
      return {
        ...this.$attrs,
        id: this.id,
        value: this.modelValue,
        type: "text",
        class: ["class" in this.$attrs ? this.$attrs["class"] : "", this.inputClass, this.errorInputClass] as string[],
        "aria-invalid": this.hasErrorMessage,
        "aria-describedby": this.errorId,
        ...(this.placeholder !== undefined ? { placeholder: this.placeholder } : {}),
        ...(this.disabled ? { disabled: this.disabled } : {}),
      };
    },

    hasErrorMessage() {
      return this.errorMessage !== undefined;
    },

    inputClass() {
      return "block w-full rounded-md border-0 py-1.5 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 focus:outline-none disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500";
    },

    usesDebounce() {
      return this.debounce !== undefined && this.debounce > 0;
    },

    errorInputClass() {
      return this.hasErrorMessage ?
          "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-inset focus:ring-red-500"
        : "text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-600";
    },
  },

  created() {
    if (!this.usesDebounce) {
      return;
    }

    this.debounceFunction = debounce(($event: Event) => {
      this.callUpdateModelValueAfterInputEvent($event);
    }, this.debounce);
  },

  methods: {
    flush() {
      if (this.debounceFunction) {
        this.debounceFunction.flush();
      }
    },

    handleInput($event: Event) {
      if (this.disabled) {
        return;
      }

      if (this.usesDebounce && this.debounceFunction) {
        this.debounceFunction($event);

        return;
      }

      this.callUpdateModelValueAfterInputEvent($event);
    },

    callUpdateModelValueAfterInputEvent($event: Event) {
      const input = $event.target as HTMLInputElement | null;

      /* istanbul ignore next -- @preserve */
      if (!input || !("value" in input)) {
        return;
      }

      const { maxLength } = this;

      if (maxLength != null) {
        if (input.value.length > maxLength) {
          input.value = input.value.substring(0, maxLength);
        }
      }

      this.updateModelValue(input.value);
    },
  },
});
</script>

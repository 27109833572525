<template>
  <div
    :class="{ 'bg-gray-50': disabled, [errorInputClass]: hasErrorMessage }"
    class="grid-cols-[auto_auto_1fr] w-[108px] min-w-8 grid m-0 gap-[1px] border border-gray-300 rounded-md placeholder-gray-400 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
  >
    <div class="relative w-full">
      <div
        :class="{ '!bg-gray-50': disabled }"
        class="whitespace-nowrap py-2 pl-3 flex items-center bg-white rounded-md text-center border border-transparent pointer-events-none relative z-[1]"
      >
        {{ hourDisplay }}
      </div>

      <label :for="hourId" class="block text-sm font-medium text-gray-700">
        <span class="sr-only">
          {{ hourSelectLabel }}
        </span>

        <!-- Hidden select behind hourDisplay via absolute positioning -->
        <select
          :id="hourId"
          v-model="localHour"
          class="mt-2 rounded-md absolute border-transparent outline-transparent ring-transparent disabled:cursor-not-allowed disabled:bg-gray-100 block w-full text-sm !p-0 !m-0 bg-none cursor-pointer border-none ring-0 outline-none h-full top-0 left-0"
          :disabled="disabled"
          @change="handleHourInput"
        >
          <option v-for="option in hourOptions" :key="option.key" :value="option.value">
            {{ option.optionLabel }}
          </option>
        </select>
      </label>
    </div>

    <div class="flex items-center">
      <span class="text-black-900">:</span>
    </div>

    <div :key="minuteKey" class="relative w-full">
      <div
        :class="{ '!bg-gray-50': disabled }"
        class="whitespace-nowrap py-2 pr-3 flex items-center bg-white rounded-md text-center border border-transparent pointer-events-none relative z-[1]"
      >
        {{ minuteDisplay }}
      </div>

      <label :for="minuteId" class="block text-sm font-medium text-gray-700">
        <span class="sr-only">
          {{ minuteSelectLabel }}
        </span>

        <!-- Hidden select behind minuteDisplay via absolute positioning -->
        <select
          :id="minuteId"
          v-model="localMinute"
          class="mt-2 rounded-md absolute border-transparent outline-transparent ring-transparent disabled:cursor-not-allowed disabled:bg-gray-100 block w-full text-sm !p-0 !m-0 bg-none cursor-pointer border-none ring-0 outline-none h-full top-0 left-0"
          :disabled="disabled"
          @change="handleMinuteInput"
        >
          <option v-for="option in minuteOptions" :key="option.key" :value="option.value">
            {{ option.optionLabel }}
          </option>
        </select>
      </label>
    </div>
  </div>

  <!-- Error message -->
  <p v-show="hasErrorMessage" :aria-hidden="!hasErrorMessage" class="mt-2 font-normal text-sm text-red-600">
    {{ errorMessage }}
  </p>
</template>

<script lang="ts">
import { RequiredStringProp } from "@/base/props/StringProp.ts";
import { dateRangeArray } from "@/date/functions/DateRange.ts";
import { requiredDayJsProp, requiredNullableDayJsProp } from "@/date/props/DayJsProps.ts";
import { maxDayJS, minDayJS } from "@/date/functions/DayMinMax.ts";
import dayjs, { Dayjs } from "dayjs";
import { defineComponent, PropType } from "vue";
import errorHandler from "@/app/error/ErrorHandler.ts";

const OptionKeyFormat = "YYYY-MM-DD HH:mm:ss";

interface SelectOption {
  key: string;
  optionLabel: string;
  value: Dayjs;
}

export default defineComponent({
  props: {
    /**
     * Min allowable date time (inclusive)
     */
    min: requiredDayJsProp,

    /**
     * Max allowable date time (inclusive)
     */
    max: requiredDayJsProp,

    /**
     * The model value. The selected time.
     */
    modelValue: requiredNullableDayJsProp,

    /** The step for each item in the select */
    step: RequiredStringProp,

    /** The label format for the select hour options */
    hourOptionLabelFormat: {
      type: String as PropType<string | undefined>,
      required: false,
      default: "HH:mm",
    },

    /** The label format to display to the user */
    hourDisplayLabelFormat: {
      type: String as PropType<string | undefined>,
      required: false,
      default: "HH",
    },

    /** The label format for the select minute options */
    minuteOptionLabelFormat: {
      type: String as PropType<string | undefined>,
      required: false,
      default: "mm",
    },

    /** The label format to display to the user */
    minuteDisplayLabelFormat: {
      type: String as PropType<string | undefined>,
      required: false,
      default: "mm A",
    },

    /**
     * The label text for the minute select
     */
    minuteSelectLabel: {
      type: String as PropType<string>,
      required: false,
      default: "Minute",
    },

    /**
     * The id for the minute select
     */
    minuteId: {
      type: String as PropType<string>,
      required: false,
      default: "minute-select",
    },

    /**
     * The id for the hour select
     */
    hourId: {
      type: String as PropType<string>,
      required: false,
      default: "hour-select",
    },

    /**
     * The label text for the hour select
     */
    hourSelectLabel: {
      type: String as PropType<string>,
      required: false,
      default: "Hour",
    },

    /**
     * Whether the input is disabled
     */
    disabled: {
      type: Boolean,
      required: false,
    },

    /**
     * Error message to render with the input
     *
     * Should only be used if there is a error to display
     */
    errorMessage: {
      required: false,
      type: String as PropType<string | undefined>,
      default: undefined,
    },
  },

  emits: {
    "update:modelValue": (_value: Dayjs | null) => true,
  },

  data() {
    const stepDuration = dayjs.duration(this.step);
    const durationMinutes = stepDuration.asMinutes();

    if (stepDuration.asMinutes() < 1 || durationMinutes > 60) {
      throw new Error("Step prop must be between 1 minute and 60 minutes");
    }

    return {
      stepDuration,
      localValue: null as Dayjs | null,
      localMinute: null as Dayjs | null,
      localHour: null as Dayjs | null,
      minuteKey: 0,
    };
  },

  computed: {
    hasErrorMessage() {
      return this.errorMessage != null;
    },

    errorInputClass() {
      return "!text-red-900 !ring-red-300 placeholder:!text-red-300 focus:!ring-inset focus:!ring-red-500 !ring-1";
    },

    hourDisplay() {
      return this.localValue?.format(this.hourDisplayLabelFormat) ?? "";
    },

    minuteDisplay() {
      return this.localValue?.format(this.minuteDisplayLabelFormat) ?? "";
    },

    hourOptions(): SelectOption[] {
      const min = this.actualMin.clone().startOf("hour");
      const max = this.actualMax.clone().endOf("hour");

      if (min.isSameOrAfter(max)) {
        errorHandler(new Error(`Min date: ${min.toISOString()} is after max date: ${max.toISOString()}`));

        return [];
      }

      if (max.diff(min, "hours") > 48) {
        throw new Error("Hour range is greater than 48 hours");
      }

      /**
       * Constructs an an array of hours
       *
       * e.g min = 2021-01-01 00:00:00
       * max = 2021-01-01 23:59:59
       *
       * range = [
       * 2021-01-01 00:00:00,
       * 2021-01-01 01:00:00,
       * 2021-01-01 02:00:00,
       * etc
       * ]
       */
      const range = dateRangeArray(min, max, "PT1H");

      return range.map((date) => ({
        key: date.format(OptionKeyFormat),
        optionLabel: date.format(this.hourOptionLabelFormat),
        value: date.clone(),
      }));
    },

    minuteOptions(): SelectOption[] {
      const options: SelectOption[] = [];

      // If we have no local value, then there can be no minute options.
      if (this.localValue == null) {
        return options;
      }

      // Use local value to provide range for minute options.
      // Use min/max boundaries if local value is outside of them.
      const current = maxDayJS([this.actualMin.clone(), this.localValue.clone().startOf("hour")]);
      const max = minDayJS([this.actualMax.clone(), this.localValue.clone().endOf("hour")]).startOf("minute");

      // If current is after max then it is invalid and we return no options.
      if (current.isSameOrAfter(max)) {
        return options;
      }

      /**
       * e.g step = 15 minutes
       * current = 2021-01-01 12:00:00
       * max = 2021-01-01 12:59:00
       *
       * range = [
       * 2021-01-01 12:00:00,
       * 2021-01-01 12:15:00,
       * 2021-01-01 12:30:00,
       * 2021-01-01 12:45:00,
       * ]
       */
      const range = dateRangeArray(current, max, this.step);

      return range.map((date) => ({
        key: date.format(OptionKeyFormat),
        optionLabel: date.format(this.minuteOptionLabelFormat),
        value: date.clone(),
      }));
    },

    actualMin(): Dayjs {
      return this.getActualBound(this.min, 0);
    },

    actualMax(): Dayjs {
      return this.getActualBound(this.max, 1);
    },
  },

  watch: {
    step() {
      // Clear local value as we don't know if minute options are valid.
      this.setLocalValue(null);

      // Can't emit model value here as it could cause infinite loop in range component.
    },

    min() {
      const hourOption = this.findHourOption(this.localValue);

      if (!hourOption) {
        this.setLocalValue(null);
        return;
      }

      this.minuteKey += 1;

      // Can't emit model value here as it could cause infinite loop in range component.
    },

    max() {
      const hourOption = this.findHourOption(this.localValue);

      if (!hourOption) {
        this.setLocalValue(null);
        return;
      }

      this.minuteKey += 1;

      // Can't emit model value here as it could cause infinite loop in range component.
    },

    modelValue(newValue) {
      // Update with new value
      this.setLocalValue(newValue);

      // Make sure we have the correct minute options.
      this.reinInLocalValue();

      this.minuteKey += 1;
    },
  },

  created() {
    this.setLocalValue(this.modelValue);
  },

  methods: {
    emitModelValue() {
      this.$emit("update:modelValue", this.localValue?.clone() ?? null);
    },

    reinInLocalValue() {
      // Get first minute option when trying to reign in minute from hour selection.
      const firstOption = this.minuteOptions[0]?.value?.clone();

      // If we have no minute options then everything is invalid so set to null.
      if (!firstOption) {
        this.setLocalValue(null);

        return;
      }

      // If we have no local value then set to first minute option.

      /* istanbul ignore if -- @preserve Currently can't test due to not being able to set hour input to null */
      if (this.localValue == null) {
        this.setLocalValue(firstOption);

        return;
      }

      // Ensures that the local value is not after the first minute option.
      // This is used when the hour is changed and the current minute selected is before the actualMin boundary.
      if (firstOption.isSameOrBefore(this.localValue)) {
        return;
      }

      this.setLocalValue(firstOption);
    },

    handleHourInput() {
      this.setLocalValue(this.localHour, false);

      this.reinInLocalValue();

      this.emitModelValue();
    },

    handleMinuteInput() {
      this.setLocalValue(this.localMinute);

      this.emitModelValue();
    },

    setLocalValue(value: Dayjs | null, setMinute: boolean = true) {
      // Set hour option from hour options
      const hourOption = this.findHourOption(value);
      this.localHour = hourOption?.value ?? null;

      // If we don't find a valid hour option then set to null
      const noHourOptionFound = hourOption == null;
      this.localValue = noHourOptionFound ? null : value;

      // Minutes are not set when doing an hour change. Instead we reign in via the reinInLocalValue method.
      if (setMinute) {
        // Set minute option from minute options
        const minuteOption = this.minuteOptions.find(
          (option) => option.key === value?.clone().startOf("minute").format(OptionKeyFormat)
        );
        this.localMinute = minuteOption?.value ?? null;
      }
    },

    findHourOption(value: Dayjs | null) {
      return this.hourOptions.find((option) => option.key === value?.clone().startOf("hour").format(OptionKeyFormat));
    },

    getActualBound(bound: Dayjs, modifier: number) {
      const boundMs = bound.valueOf();

      const actualBound =
        boundMs - ((boundMs % this.stepDuration.asMilliseconds()) - this.stepDuration.asMilliseconds() * modifier);

      // @ts-expect-error - Get the timezone from the bound date
      const timezone: string = bound.tz()["$x"]["$timezone"];

      return dayjs.tz(new Date(actualBound), timezone);
    },
  },
});
</script>

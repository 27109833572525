<template>
  <div class="custom-chart-tooltip">
    <div class="apexcharts-tooltip-title">
      {{ config.title }}
    </div>

    <div
      v-for="(element, index) in config.elements"
      :key="index"
      class="apexcharts-tooltip-series-group apexcharts-active"
    >
      <span class="apexcharts-tooltip-marker" :style="{ backgroundColor: element.markerColor }" />

      <div v-if="element.subList === undefined" class="apexcharts-tooltip-text">
        <div class="apexcharts-tooltip-y-group">
          <span class="apexcharts-tooltip-text-y-label">
            {{ element.label }}
          </span>

          <span class="apexcharts-tooltip-text-y-value">{{ element.value }} </span>
        </div>
      </div>

      <div v-else class="apexcharts-tooltip-y-group border-t border-gray-200 pt-2">
        <div class="text-xs apexcharts-tooltip-text font-medium">
          {{ element.label }}
        </div>

        <ul>
          <li v-for="(subElement, subIndex) in element.subList" :key="subIndex">
            <span class="text-xs pr-2 apexcharts-tooltip-text-y-label">
              {{ subElement.label }}
            </span>

            <span class="text-xs apexcharts-tooltip-y-value">
              {{ subElement.value }}
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";
import type { BaseChartTooltipConfigInterface } from "./BaseChartInterface.ts";

export default defineComponent({
  name: "BaseChartTooltip",

  props: {
    /** The configuration for the tooltip */
    config: {
      type: Object as PropType<BaseChartTooltipConfigInterface>,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.apexcharts-tooltip-title {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 12px;
}

.apexcharts-tooltip-series-group {
  order: 1;
  display: flex;
}

.apexcharts-tooltip-text {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 12px;
}

.apexcharts-tooltip-y-group > a:hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>

<template>
  <div class="rounded-md bg-red-50 p-4">
    <div class="flex">
      <div class="flex-shrink-0">
        <FontAwesomeIcon :icon="['fas', 'circle-xmark']" class="h-5 w-5 text-red-400" data-testid="alert-icon" />
      </div>

      <div class="ml-3">
        <h3 class="text-sm font-medium text-red-800">{{ alertTitle }}</h3>

        <div v-if="errorMessages.length > 0" class="mt-2 text-sm text-red-700">
          <ul class="list-disc space-y-1 pl-5">
            <li v-for="errorMessage in errorMessages" :key="errorMessage" :aria-label="errorMessage">
              {{ errorMessage }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import type { PropType } from "vue";
import { defineComponent } from "vue";

library.add(faCircleXmark);

export default defineComponent({
  name: "BaseAlert",

  components: {
    FontAwesomeIcon,
  },

  props: {
    /** The title of the alert */
    alertTitle: {
      required: true,
      type: String,
    },

    /**
     * An array of error messages to display.
     *
     * Will be a bullet point list.
     */
    errorMessages: {
      required: false,
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
});
</script>

<template>
  <button
    :class="[active ? 'bg-gray-50 text-blue-600' : 'hover:text-blue-600 text-gray-700']"
    class="flex w-full justify-between hover:bg-gray-50 cursor-pointer items-center rounded-md leading-6 font-semibold"
    type="button"
    :role="htmlRole"
    @click="handleClick()"
  >
    <div class="flex pr-3 py-2 gap-3 items-center" :class="withoutIcon ? 'pl-3' : 'pl-2'">
      <div
        v-if="!withoutIcon"
        :class="!active && 'text-gray-400 group-hover:text-blue-600'"
        class="h-6 w-6 shrink-0 flex items-center justify-center"
      >
        <!-- @todo use passed components instead of slots -->
        <slot name="icon"></slot>
      </div>

      <div class="text-sm leading-6 font-semibold mt-0.5">{{ label }}</div>
    </div>

    <div v-if="expandable" class="py-1 pr-4 rounded text-gray-300 flex items-center justify-center text-xs">
      <div v-if="expanded" data-testid="chevron-up-icon">
        <ChevronUpIcon class="h-4 w-4"></ChevronUpIcon>
      </div>

      <div v-else data-testid="chevron-down-icon">
        <ChevronDownIcon class="h-4 w-4"></ChevronDownIcon>
      </div>
    </div>
  </button>
</template>

<script lang="ts">
import ChevronDownIcon from "@heroicons/vue/24/solid/ChevronDownIcon";
import ChevronUpIcon from "@heroicons/vue/24/solid/ChevronUpIcon";
import type { PropType } from "vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseNavMenuButton",

  components: {
    ChevronUpIcon,
    ChevronDownIcon,
  },

  props: {
    /**
     * Text to display in the nav link
     */
    label: {
      type: String as PropType<string>,
      required: true,
    },

    /**
     * Whether the nav item link is active
     */
    active: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },

    /**
     * Whether the component can have an expanded state.s
     */
    expandable: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },

    /**
     * Whether the component should be in an expanded state
     */
    expanded: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },

    /** The accessible html role attribute */
    htmlRole: {
      required: true,
      type: String as PropType<"button" | "link">,
    },

    /** Whether to hide the icon */
    withoutIcon: {
      type: [Boolean, undefined] as PropType<boolean | undefined>,
      required: false,
      default: false,
    },
  },

  emits: {
    click: () => true,
  },

  setup(props) {
    if (!props.expandable && props.expanded) {
      throw new TypeError("Cannot specify the expanded prop when not expandable.");
    }
  },

  methods: {
    handleClick() {
      this.$emit("click");
    },
  },
});
</script>

<template>
  <div v-click-away="onClickAway" class="relative">
    <BaseFilterMenuButton
      :active-count="activeCount"
      :label="label"
      @click="handleButtonClick()"
    ></BaseFilterMenuButton>

    <div
      v-if="showDropdown"
      class="mt-2"
      :class="dropdownClassList"
      data-testid="baseFilterMenuFilterDropdownContainer"
    >
      <slot :update-show-dropdown="updateShowDropdown"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import BaseFilterMenuButton from "@/base/components/buttons/BaseFilterMenuButton.vue";
import type { XPosition } from "@/base/composables/AbsoluteRelativePositionComposable.ts";
import {
  useAbsoluteRelativePositionComposable,
  XLeftPosition,
  XRightPosition,
  YBottomPosition,
} from "@/base/composables/AbsoluteRelativePositionComposable.ts";
import { ClickAway } from "@/base/directives/ClickAwayDirective.ts";
import type { PropType } from "vue";
import { defineComponent } from "vue";
import { FilterLeftPosition } from "../BaseFilterMenu.vue";

export type FilterMenuPosition = XPosition;

export default defineComponent({
  name: "BaseFilterMenuFilter",

  components: {
    BaseFilterMenuButton,
  },

  directives: {
    ClickAway,
  },

  props: {
    /** Filter menu position in filter list */
    position: {
      type: String as PropType<FilterMenuPosition>,
      required: true,
    },

    /** The filter label */
    label: {
      type: String as PropType<string>,
      required: true,
    },

    /** The amount of options that are active */
    activeCount: {
      type: Number as PropType<number | undefined>,
      required: false,
      default: undefined,
    },
  },

  setup(props) {
    const { classList: dropdownClassList } = useAbsoluteRelativePositionComposable(
      props.position === FilterLeftPosition ? XRightPosition : XLeftPosition,
      YBottomPosition
    );

    return {
      dropdownClassList,
    };
  },

  data() {
    return {
      showDropdown: false,
    };
  },

  methods: {
    updateShowDropdown(showDropdown: boolean) {
      this.showDropdown = showDropdown;
    },

    handleButtonClick() {
      this.showDropdown = !this.showDropdown;
    },

    onClickAway() {
      this.showDropdown = false;
    },
  },
});
</script>

<template>
  <tr>
    <td colspan="100%" :class="cellClassList" class="animate-pulse" data-testid="loading-row">
      <div class="flex items-center justify-between w-full">
        <div>
          <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>

          <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>

        <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
      </div>
    </td>
  </tr>
</template>

<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseTableLoadingRow",

  props: {
    /** Class list which applies it to the cell */
    cellClassList: {
      type: [Array, String] as PropType<string[] | string | undefined>,
      required: false,
      default: undefined,
    },
  },
});
</script>

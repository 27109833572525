<template>
  <div class="relative z-[9999]" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

    <div class="fixed inset-0 z-10 overflow-y-auto">
      <div class="relative flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <div
          class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
        >
          <div
            v-if="theme === 'success'"
            class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100"
            data-testid="success-icon"
          >
            <CheckIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
          </div>

          <div
            v-else
            class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100"
            data-testid="danger-icon"
          >
            <ExclamationTriangleIcon class="h-6 w-6 text-red-600" aria-hidden="true" />
          </div>

          <div>
            <div class="mt-3 text-center sm:mt-5">
              <h3 id="modal-title" class="text-base font-semibold leading-6 text-gray-900">{{ modalTitle }}</h3>

              <div class="mt-2">
                <p class="text-sm text-gray-500">
                  {{ modalDescription }}
                </p>
              </div>

              <slot></slot>

              <slot name="alert"></slot>
            </div>
          </div>

          <div class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
            <button
              type="button"
              class="inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:col-start-2"
              :class="{
                'bg-red-600 hover:bg-red-500 focus-visible:outline-red-600': theme === 'danger',
                'bg-blue-600 hover:bg-blue-500 focus-visible:outline-blue-600': theme === 'success',
              }"
              :disabled="disabled"
              @click="confirmClick()"
            >
              {{ confirmButtonText }}
            </button>

            <button
              type="button"
              data-testid="modal-cancel-button"
              :disabled="disabled"
              class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
              @click="cancelClick()"
            >
              {{ cancelButtonText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { CheckIcon, ExclamationTriangleIcon } from "@heroicons/vue/24/outline";
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseConfirmModal",
  components: { ExclamationTriangleIcon, CheckIcon },

  props: {
    /**
     * The modal title, this is required as the modal usage should be specific to each use case
     *
     */
    modalTitle: {
      required: true,
      type: String,
    },

    /** The modal description */
    modalDescription: {
      required: true,
      type: String,
    },

    /**
     * The confirm button text this is the positive/confirmation action text
     *
     */
    confirmButtonText: {
      required: false,
      type: String,
      default: "Confirm",
    },

    /**
     * The cancel button text this is the negative/cancel action text
     *
     */
    cancelButtonText: {
      required: false,
      type: String,
      default: "Cancel",
    },

    /** The theme of the modal. */
    theme: {
      type: String,
      required: false,
      default: "danger",
      validator: (value: string) => ["danger", "success"].includes(value),
    },

    /** Disable all controls on the modal */
    disabled: {
      type: Boolean,
      required: false,
    },
  },

  emits: {
    cancel: () => true,
    confirm: () => true,
  },

  computed: {},

  methods: {
    /**
     * Handles the click event on the button
     */
    confirmClick() {
      this.$emit("confirm");
    },

    /**
     * Handles the click event on the button
     */
    cancelClick() {
      this.$emit("cancel");
    },
  },
});
</script>

/* eslint-disable import/prefer-default-export */

import { isDayjs } from "dayjs";

/**
 * Check if the value is a valid DayJS instance.
 * @param value The value to check.
 * @returns True if the value is a valid DayJS instance, false otherwise.
 */
function isDayJSValid(value: unknown): boolean {
  return isDayjs(value) && value.isValid();
}

export { isDayJSValid };

import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import { hasInjectionContext, inject } from "vue";

export const createContextError = (): Error => new Error("No context found for inject");
export const createInvalidDateError = (): TypeError => new TypeError("Invalid date");
export const createTimeZoneTypeError = (): TypeError =>
  new TypeError("Expected timezone inject to be string, got null");

/**
 * Reusable code for timezones.
 * @param timezone Timezone to use or use the injected one
 * @returns code
 */
export default function useContextDateTimeZoneComposable(timezone?: string | undefined): {
  parseConvertInContextTimeZone: (date: string) => Dayjs;
  formatInContextTimeZone: (date: string, format: string) => string;
  isTodayInContextTimeZone: (date: Dayjs | string) => boolean;
  getContextTimeZone: () => string;
  getTodayInContextTimeZone: () => Dayjs;
  parseInputIntoUtc: (date: string) => Dayjs;
  formatContextTimezoneWithToday: (date: string, todayFormat?: string, otherFormat?: string) => string;
  isInPastInContextTimeZone: (date: string) => boolean;
} {
  if (timezone == null && !hasInjectionContext()) {
    throw createContextError();
  }

  const contextTimeZone: string | undefined = timezone ?? inject<string | undefined>("timezone");

  if (contextTimeZone == null) {
    throw createTimeZoneTypeError();
  }

  const convertDateTimeZoneToContextTimeZone = (date: Dayjs): Dayjs => {
    let localDate: Dayjs = date.clone();

    if (!localDate.isValid()) {
      throw createInvalidDateError();
    }

    localDate = localDate.tz(contextTimeZone);

    return localDate;
  };

  const isTodayInContextTimeZone = (date: Dayjs | string): boolean => {
    const dateObject = typeof date === "string" ? dayjs.utc(date) : date.clone();

    if (!dateObject.isValid()) {
      throw createInvalidDateError();
    }

    const convertedDate: Dayjs = convertDateTimeZoneToContextTimeZone(dateObject);

    return dayjs.utc().tz(contextTimeZone).startOf("day").isSame(convertedDate.startOf("day"), "dates");
  };

  /**
   * Parse the date string in UTC and convert to the context time zone.
   * @param date UTC date string
   * @returns Context time zone date
   */
  const parseConvertInContextTimeZone = (date: string): Dayjs => {
    return convertDateTimeZoneToContextTimeZone(dayjs.utc(date));
  };

  /**
   * Get today's date in the context time zone.
   * @returns Today's date
   */
  const getTodayInContextTimeZone = (): Dayjs => {
    return dayjs.utc().tz(contextTimeZone);
  };

  return {
    /**
     * Parses a date time string in the context time zone and converts it to UTC.
     * @param date The date string to parse
     * @returns The UTC date
     */
    parseInputIntoUtc(date: string): Dayjs {
      const timezoneDate: Dayjs = dayjs.tz(date, contextTimeZone);

      return timezoneDate.utc();
    },
    getContextTimeZone(): string {
      return contextTimeZone;
    },

    formatContextTimezoneWithToday(
      date: string,
      todayFormat: string = "[Today at] HH:mm",
      otherFormat: string = "Do MMM YYYY HH:mm"
    ): string {
      const timezoneDate = parseConvertInContextTimeZone(date);

      return isTodayInContextTimeZone(timezoneDate) ?
          timezoneDate.format(todayFormat)
        : timezoneDate.format(otherFormat);
    },

    /**
     * Parse the date string in UTC and format in the context time zone.
     * @param date UTC date string
     * @param format Date format
     * @returns Context time zone date
     */
    formatInContextTimeZone(date: string, format: string): string {
      const localDate: Dayjs = dayjs.utc(date);

      if (!localDate.isValid()) {
        throw createInvalidDateError();
      }

      return convertDateTimeZoneToContextTimeZone(localDate).format(format);
    },

    isInPastInContextTimeZone(date: string): boolean {
      const convertedDate: Dayjs = parseConvertInContextTimeZone(date);

      const todayDate: Dayjs = getTodayInContextTimeZone();

      return todayDate.isAfter(convertedDate);
    },

    isTodayInContextTimeZone,
    parseConvertInContextTimeZone,
    getTodayInContextTimeZone,
  };
}

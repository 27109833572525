<template>
  <div :class="containerClass">
    <component :is="wrapper">
      <BaseInputSelectTime
        :min="fromMin"
        :max="fromMax"
        :model-value="localFrom"
        :hour-id="fromHourSelectId"
        :minute-id="fromMinuteSelectId"
        :hour-select-label="fromHourSelectLabel"
        :minute-select-label="fromMinuteSelectLabel"
        :disabled="disabled"
        :error-message="fromErrorMessage"
        v-bind="sharedTimeInputProps"
        @update:model-value="handleFromInput"
      ></BaseInputSelectTime>
    </component>

    <component :is="wrapper">
      <BaseInputSelectTime
        :min="localToMin"
        :max="toMax"
        :model-value="localTo"
        :hour-id="toHourSelectId"
        :minute-id="toMinuteSelectId"
        :hour-select-label="toHourSelectLabel"
        :minute-select-label="toMinuteSelectLabel"
        :disabled="disabled"
        :error-message="toErrorMessage"
        v-bind="sharedTimeInputProps"
        @update:model-value="handleToInput"
      ></BaseInputSelectTime>
    </component>
  </div>
</template>

<script lang="ts">
import errorHandler from "@/app/error/ErrorHandler.ts";
import BaseInputSelectTime from "@/base/components/inputs/BaseInputSelectTime.vue";
import { OptionalStringProp, RequiredStringProp } from "@/base/props/StringProp.ts";
import { maxDayJS } from "@/date/functions/DayMinMax.ts";
import { optionalNullableDayJsProp, requiredDayJsProp } from "@/date/props/DayJsProps.ts";
import dayjs, { Dayjs } from "dayjs";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  components: {
    BaseInputSelectTime,
  },

  props: {
    /**
     * The wrapper element for the input
     */
    wrapper: {
      type: String,
      required: false,
      default: "div",
    },

    /**
     * The class for the overall container element
     */
    containerClass: {
      type: String,
      required: false,
      default: "flex space-x-2",
    },

    /**
     * The initial start date time
     * WARNING: This cannot be updated after the component is created due to the date range having rules in controlling the valid date range.
     */
    initialFrom: optionalNullableDayJsProp,

    /**
     * The initial to date time.
     * WARNING: This cannot be updated after the component is created due to the date range having rules in controlling the valid date range.
     */
    initialTo: optionalNullableDayJsProp,

    /**
     * The min date time the start date can be
     */
    fromMin: requiredDayJsProp,

    /**
     * The max date time the start date can be
     */
    fromMax: requiredDayJsProp,

    /**
     * The min date time the end date can be
     */
    toMin: requiredDayJsProp,

    /**
     * The max date time the end date can be
     */
    toMax: requiredDayJsProp,

    /**
     * Iso duration string, used to increment the minute options
     */
    step: RequiredStringProp,

    /**
     * The label format to use in the hour select options
     */
    hourOptionLabelFormat: OptionalStringProp,
    /**
     * The label format to use in the hour select options
     */
    minuteOptionLabelFormat: OptionalStringProp,

    /**
     * The label format to display to the user
     */
    minuteDisplayLabelFormat: OptionalStringProp,
    /**
     * The label format to display to the user
     */
    hourDisplayLabelFormat: OptionalStringProp,
    /**
     * The id to use for the hour select for the start date
     */
    toHourSelectId: {
      type: String,
      required: false,
      default: "to-hour-select",
    },

    /**
     * The id for the minute select for the start date
     */
    toMinuteSelectId: {
      type: String,
      required: false,
      default: "to-minute-select",
    },

    /**
     * The id to use for the hour select for the end date
     */
    fromMinuteSelectId: {
      type: String,
      required: false,
      default: "from-minute-select",
    },

    /**
     * The id for the minute select for the end date
     */
    fromHourSelectId: {
      type: String,
      required: false,
      default: "from-hour-select",
    },

    /**
     * The label for the hour select for the start date
     */
    toHourSelectLabel: {
      type: String,
      required: false,
      default: "End Hour",
    },

    /**
     * The label for the minute select for the start date
     */
    toMinuteSelectLabel: {
      type: String,
      required: false,
      default: "End Minute",
    },

    /**
     * The label for the hour select for the end date
     */
    fromHourSelectLabel: {
      type: String,
      required: false,
      default: "Start Hour",
    },

    /**
     * The label for the minute select for the end date
     */
    fromMinuteSelectLabel: {
      type: String,
      required: false,
      default: "Start Minute",
    },

    /**
     * Disable the input
     */
    disabled: {
      type: Boolean,
      required: false,
    },

    /**
     * The error message to display for the start date
     */
    fromErrorMessage: {
      type: String as PropType<string | undefined>,
      required: false,
      default: undefined,
    },

    /**
     * The error message to display for the end date
     */
    toErrorMessage: {
      type: String as PropType<string | undefined>,
      required: false,
      default: undefined,
    },
  },

  emits: {
    "update:range": (_range: { from: Dayjs | null; to: Dayjs | null }): boolean => true,
  },

  data() {
    if (this.initialFrom && this.initialTo && this.initialFrom.isAfter(this.initialTo)) {
      // Don't throw error as reignInDates will handle this.
      errorHandler(
        new Error(
          `Initial from date: ${this.initialFrom.toISOString()} is after initial to date: ${this.initialTo.toISOString()}`
        )
      );
    }

    return {
      localFrom: this.initialFrom?.clone() ?? null,
      localTo: this.initialTo?.clone() ?? null,
    };
  },

  computed: {
    /**
     * Shared props for both select time inputs
     * @returns The shared props
     */
    sharedTimeInputProps() {
      return {
        step: this.step,
        hourOptionLabelFormat: this.hourOptionLabelFormat,
        minuteOptionLabelFormat: this.minuteOptionLabelFormat,
        hourDisplayLabelFormat: this.hourDisplayLabelFormat,
        minuteDisplayLabelFormat: this.minuteDisplayLabelFormat,
      };
    },

    /**
     * Get the min date time for the to date.
     *
     * This is used to prevent the start date from being after the end date.
     * @returns The min date time for the to date
     */
    localToMin() {
      const defaultToMin = this.toMin.clone();

      if (!this.localFrom) {
        return defaultToMin;
      }

      return maxDayJS([defaultToMin, this.localFrom.clone().add(dayjs.duration(this.step))]);
    },
  },

  watch: {
    toMin() {
      this.reignInDates(this.localFrom, this.localTo);
    },

    toMax() {
      this.reignInDates(this.localFrom, this.localTo);
    },

    fromMin() {
      this.reignInDates(this.localFrom, this.localTo);
    },

    fromMax() {
      this.reignInDates(this.localFrom, this.localTo);
    },
  },

  async created() {
    this.reignInAndEmitIfChanged();
  },

  methods: {
    async reignInAndEmitIfChanged() {
      const originalFrom = this.localFrom?.clone() ?? null;
      const originalTo = this.localTo?.clone() ?? null;

      await this.reignInDates(this.localFrom, this.localTo);

      // There is a chance some of the values could have changed so emit the update
      if (
        (originalFrom?.isSame(this.localFrom) || originalFrom === this.localFrom) &&
        (originalTo?.isSame(this.localTo) || originalTo === this.localTo)
      ) {
        return;
      }

      this.emitRangeUpdate();
    },

    /**
     * Reign in the to date to prevent illegal date ranges
     * @param from The from date
     * @param to  The to date to reign in
     * @returns The new date to use and if the date has changed
     */
    reignInTo(from: Dayjs | null, to: Dayjs | null): Dayjs | null {
      let localTo = to?.clone() ?? null;

      // To is null so no need to do anything
      if (to === null) {
        return null;
      }

      // Clear to if there is no from selected
      if (from === null) {
        return null;
      }

      // From is after or equal to, need to reign in to.
      if (from.isSameOrAfter(to)) {
        // If illegal to date, then try to set it to the next step after the current from date
        // e.g. to = 12:00, from = 12:00, step = 15 minutes. Set to = 12:15
        localTo = from.clone().add(dayjs.duration(this.step));
        // Do not return, have to make sure new date is valid.
      }

      // Check if to is after the max allowed date
      // This can happen if the duration is added past the max date.
      // This usually happens when there are no to options available.
      if (this.toMax && localTo?.isAfter(this.toMax)) {
        localTo = null;
      }

      // Check if to is before the min allowed date
      // This should not really happen and is defensive code.
      /* istanbul ignore if -- @preserve */
      if (this.localToMin && localTo?.isBefore(this.localToMin)) {
        localTo = null;
      }

      return localTo;
    },

    async reignInDates(from: Dayjs | null, to: Dayjs | null): Promise<void> {
      let localForm = from?.clone() ?? null;

      // If no longer in boundary then set to null
      if (from && from.isAfter(this.fromMax)) {
        localForm = null;
      }

      if (from && from.isBefore(this.fromMin)) {
        localForm = null;
      }

      const reignedInTo = this.reignInTo(localForm, to);
      this.localTo = reignedInTo;

      await this.$nextTick();

      // localTo has to be updated before localToMin to not result in minute options being created incorrectly.
      // localToMin gets updated when localTo is updated.
      this.localFrom = localForm;
    },

    async handleFromInput(from: Dayjs | null) {
      await this.reignInDates(from, this.localTo);

      this.emitRangeUpdate();
    },

    emitRangeUpdate() {
      this.$emit("update:range", {
        from: this.localFrom?.clone() ?? null,
        to: this.localTo?.clone() ?? null,
      });
    },

    async handleToInput(to: Dayjs | null) {
      await this.reignInDates(this.localFrom, to);

      this.emitRangeUpdate();
    },
  },
});
</script>

<template>
  <div class="flex" :class="!divide ? listDefaultClassList : listDivideClassList" data-testid="baseFilterMenuList">
    <div
      v-for="(filter, index) in filters"
      :key="filter.key"
      :class="divide && index > 0 && 'pl-4'"
      data-testid="baseFilterMenuListFilter"
    >
      <BaseFilterMenuMultiSelectFilter
        v-if="filter.type === 'multiselect'"
        :options="filter.options"
        :position="filter.position"
        :label="filter.label"
        :no-wrap="filter.noWrap"
        @update:options="handleOptionsUpdate(index, filter, $event)"
      >
      </BaseFilterMenuMultiSelectFilter>

      <BaseFilterMenuSelectFilter
        v-if="filter.type === 'select'"
        :options="filter.options"
        :position="filter.position"
        :label="filter.label"
        :no-wrap="filter.noWrap"
        @update:model-value="handleFilterValueUpdate(index, filter, $event)"
      >
      </BaseFilterMenuSelectFilter>

      <BaseFilterSearchFilter
        v-if="filter.type === 'search'"
        :position="filter.position"
        :label="filter.label"
        :model-value="filter.value"
        @update:model-value="handleFilterSearchUpdate(index, filter, $event)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import BaseFilterMenuMultiSelectFilter from "@/base/components/menus/filterMenus/filters/BaseFilterMenuMultiSelectFilter.vue";
import BaseFilterMenuSelectFilter from "@/base/components/menus/filterMenus/filters/BaseFilterMenuSelectFilter.vue";
import useModelValue from "@/base/composables/ModelValueComposable.ts";
import { defineComponent, type PropType } from "vue";
import type { MultiSelectFilterOption } from "../../dropdownMenus/BaseFilterMultiSelectOptionDropdownMenu.vue";
import type { BaseSearchFilter, Filter, MultiSelectFilter, SingleSelectFilter } from "../BaseFilterMenu.vue";
import BaseFilterSearchFilter from "../filters/BaseFilterSearchFilter.vue";

export default defineComponent({
  name: "BaseFilterMenuList",

  components: { BaseFilterMenuMultiSelectFilter, BaseFilterMenuSelectFilter, BaseFilterSearchFilter },
  props: {
    /**
     * List of filters.
     */
    filters: {
      type: Array as PropType<Filter[]>,
      required: true,
    },

    /**
     * Whether to divide the list by a gray line.
     */
    divide: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },

  setup() {
    const { updateModelValue: updateFilters } = useModelValue<Filter[]>("filters");
    return {
      updateFilters,
      listDefaultClassList: Object.freeze(["gap-8"]),
      listDivideClassList: Object.freeze(["divide-gray-200", "divide-x", "gap-4"]),
    };
  },

  methods: {
    handleFilterValueUpdate(index: number, filter: SingleSelectFilter, value: unknown) {
      const filters = [...this.filters];
      filters[index] = { ...filter, value };
      this.updateFilters(filters);
    },

    handleFilterSearchUpdate(index: number, filter: BaseSearchFilter, search: string) {
      const filters = [...this.filters];
      filters[index] = { ...filter, value: search };
      this.updateFilters(filters);
    },

    handleOptionsUpdate(index: number, filter: MultiSelectFilter, options: MultiSelectFilterOption[]) {
      const filters = [...this.filters];
      filters[index] = { ...filter, options };
      this.updateFilters(filters);
    },
  },
});
</script>

<template>
  <li
    :class="[itemDefaultClassList, active && itemActiveClassList, (noWrap ?? false) && 'whitespace-nowrap']"
    role="menuitem"
    tabindex="0"
    @click="handleClick()"
    @keypress.enter.prevent="handleClick()"
  >
    {{ label }}
  </li>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

export default defineComponent({
  name: "BaseDropdownMenuItem",

  props: {
    /**
     * Whether the item is considered to be active or selected
     */
    active: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },

    /**
     * Text for the menu item
     */
    label: {
      type: String as PropType<string>,
      required: true,
    },

    /**
     * Whether the item should not wrap its text.
     */
    noWrap: {
      type: Boolean as PropType<boolean | undefined>,
      required: false,
      default: undefined,
    },
  },

  emits: {
    click: () => true,
  },

  setup() {
    return {
      itemDefaultClassList: "block px-4 py-2 cursor-pointer text-sm text-gray-700 hover:bg-gray-100",
      itemActiveClassList: "bg-gray-100",
    };
  },

  methods: {
    handleClick() {
      this.$emit("click");
    },
  },
});
</script>

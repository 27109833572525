<template>
  <BaseInputCheckbox
    :id="`${optionKey}-filter-option`"
    :name="`${optionKey}FilterOption`"
    :model-value="modelValue"
    :label="label"
    :label-class="'flex items-center gap-1 pr-6 leading-none ' + (noWrap ? 'whitespace-nowrap' : '')"
    @update:model-value="handleUpdate($event)"
  ></BaseInputCheckbox>
</template>

<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";
import BaseInputCheckbox from "@/base/components/inputs/BaseInputCheckbox.vue";
import useModelValue from "@/base/composables/ModelValueComposable.ts";

export default defineComponent({
  name: "BaseFilterMultiSelectOptionDropdownMenuItem",

  components: {
    BaseInputCheckbox,
  },

  props: {
    /**
     * How the menu item should be uniquely identified.
     */
    optionKey: {
      type: [String, Number] as PropType<number | string>,
      required: true,
    },

    /**
     * Display text for the menu item.
     */
    label: {
      type: String as PropType<string>,
      required: true,
    },

    /**
     * Options for the filter.
     */
    modelValue: {
      type: Boolean as PropType<boolean>,
      required: true,
    },

    /**
     * Whether the dropdown menu item should not wrap its text.
     */
    noWrap: {
      type: Boolean as PropType<boolean | undefined>,
      required: false,
      default: false,
    },
  },

  emits: {
    "update:modelValue": (value: boolean) => typeof value === "boolean",
  },

  setup() {
    const { updateModelValue } = useModelValue<boolean>();

    return {
      updateModelValue,
    };
  },

  methods: {
    handleUpdate(value: boolean) {
      this.updateModelValue(value);
    },
  },
});
</script>

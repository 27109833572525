<template>
  <div class="text-center">
    <FontAwesomeIcon
      data-testid="base-empty-state-icon"
      class="mx-auto h-12 w-12 text-gray-400 stroke-1"
      :icon="icon"
    />

    <h3 class="mt-2 text-sm font-semibold text-gray-900">{{ title }}</h3>

    <p class="mt-1 text-sm text-gray-500">{{ description }}</p>

    <div v-if="actionable" class="mt-6">
      <BaseButton :button-text="actionText ?? ''" theme="indigo" size="md" @click="handleClick()"></BaseButton>
    </div>
  </div>
</template>

<script lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import type { PropType } from "vue";
import { defineComponent } from "vue";
import BaseButton from "@/base/components/buttons/BaseButton.vue";

type IconType = string[];
type ActionTextType = string | undefined;

interface BaseEmptyState {
  icon: IconType;
  title: string;
  description: string;
}

export interface BaseEmptyStateActionable extends BaseEmptyState {
  actionable: true;
  actionText: ActionTextType;
}

export interface BaseEmptyStateNonActionable extends BaseEmptyState {
  actionable?: false;
  actionText?: undefined;
}

export default defineComponent({
  name: "BaseEmptyState",

  components: {
    BaseButton,
    FontAwesomeIcon,
  },

  props: {
    /**
     * Icon to be displayed.
     */
    icon: {
      type: Array as PropType<IconType>,
      required: true,
    },

    /**
     * Title text.
     */
    title: {
      type: String,
      required: true,
    },

    /**
     * Subtitle/description text.
     */
    description: {
      type: String,
      required: true,
    },

    /**
     * Whether the empty state is actionable ie. show a button
     */
    actionable: {
      type: Boolean,
      required: false,
    },

    /**
     * The action button's text.
     */
    actionText: {
      type: String as PropType<ActionTextType>,
      required: false,
      default: undefined,
    },
  },

  emits: {
    click: /* istanbul ignore next */ () => true,
  },

  setup(props) {
    if (props.actionable && props.actionText === undefined) {
      throw new TypeError("Action button text is required when actionable is true");
    }
  },

  methods: {
    handleClick() {
      this.$emit("click");
    },
  },
});
</script>

<template>
  <div class="flex justify-between gap-8 w-full">
    <BaseFilterMenuList
      data-testid="baseFilterMenuLeftFilters"
      :filters="leftFilters"
      :divide="divide"
      @update:filters="handleFiltersUpdate($event)"
    >
    </BaseFilterMenuList>

    <BaseFilterMenuList
      data-testid="baseFilterMenuRightFilters"
      :filters="rightFilters"
      :divide="divide"
      @update:filters="handleFiltersUpdate($event)"
    >
    </BaseFilterMenuList>
  </div>
</template>

<script lang="ts">
import BaseFilterMenuList from "@/base/components/menus/filterMenus/lists/BaseFilterMenuList.vue";
import useModelValue from "@/base/composables/ModelValueComposable.ts";
import type { PropType } from "vue";
import { defineComponent } from "vue";
import { z } from "zod";
import { MultiSelectFilterOptionSchema } from "../dropdownMenus/BaseFilterMultiSelectOptionDropdownMenu.vue";
import { SelectFilterOptionSchema } from "../dropdownMenus/BaseFilterSelectOptionDropdownMenu.vue";

export const FilterLeftPosition = "left";
export const FilterRightPosition = "right";

export const SelectFilterType = "select";
export const MultiSelectFilterType = "multiselect";
export const SearchFilterType = "search";

export type FilterPosition = typeof FilterLeftPosition | typeof FilterRightPosition;

export const BaseFilterSchema = z.object({
  key: z.string(),
  label: z.string(),
  position: z.enum([FilterLeftPosition, FilterRightPosition]),
  value: z.unknown(),
  options: z.unknown(),
});

export const MultiSelectFilterSchema = z
  .object({
    type: z.literal(MultiSelectFilterType),
    options: z.array(MultiSelectFilterOptionSchema),
    noWrap: z.boolean().optional(),
  })
  .merge(BaseFilterSchema.omit({ options: true }));

export const SingleSelectFilterSchema = z
  .object({
    type: z.literal(SelectFilterType),
    options: z.array(SelectFilterOptionSchema),
    noWrap: z.boolean().optional(),
  })
  .merge(BaseFilterSchema.omit({ options: true }));

export const BaseSearchSchema = z
  .object({
    type: z.literal(SearchFilterType),
    options: z.null(),
    value: z.string().nullable(),
    noWrap: z.boolean().optional(),
  })
  .merge(BaseFilterSchema.omit({ options: true, value: true }));

export interface BaseFilter extends z.infer<typeof BaseFilterSchema> {}

export interface MultiSelectFilter extends z.infer<typeof MultiSelectFilterSchema> {}

export interface SingleSelectFilter extends z.infer<typeof SingleSelectFilterSchema> {}

export interface BaseSearchFilter extends z.infer<typeof BaseSearchSchema> {}

export const FilterSchema = z.union([MultiSelectFilterSchema, SingleSelectFilterSchema, BaseSearchSchema]);

export type Filter = z.infer<typeof FilterSchema>;

export default defineComponent({
  name: "BaseFilterMenu",
  components: {
    BaseFilterMenuList,
  },

  props: {
    /**
     * The filters to be used
     */
    filters: {
      type: Array as PropType<Filter[]>,
      required: true,
    },

    /**
     * Whether to divide filters by a vertical line.
     */
    divide: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },

  emits: {
    "update:filters": (_filters: Filter[]) => true,
  },

  setup() {
    const { updateModelValue: updateFilters } = useModelValue<Filter[]>("filters");

    return {
      updateFilters,
    };
  },

  computed: {
    leftFilters(): Filter[] {
      return this.filters.filter((filter) => filter.position === FilterLeftPosition);
    },

    rightFilters(): Filter[] {
      return this.filters.filter((filter) => filter.position === FilterRightPosition);
    },
  },

  methods: {
    handleFiltersUpdate(updateFilters: Filter[]) {
      const filters = [...this.filters];

      updateFilters.forEach((updateFilter: Filter) => {
        const filterIndex = this.filters.findIndex((baseFilter) => baseFilter.key === updateFilter.key);

        filters[filterIndex] = updateFilter;
      });

      this.updateFilters(filters);
    },
  },
});
</script>

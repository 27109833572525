/* eslint-disable import/prefer-default-export */
import { isDayJSValid } from "@/validation/zod/custom/ZodDayJS.ts";
import dayjs, { Dayjs } from "dayjs";
import { z } from "zod";

/**
 * Create a date range array.
 * @param dateStart The start Date.
 * @param dateEnd The end Date.
 * @param step Iso duration string.
 * @returns The date range array.
 */
function dateRangeArray(dateStart: Dayjs, dateEnd: Dayjs, step: string): Dayjs[] {
  const dateRanges: Dayjs[] = [];

  let localDateStart = dateStart.clone();
  const localDateEnd = dateEnd.clone();

  if (!localDateStart.isValid() || !localDateEnd.isValid()) {
    throw new Error("Invalid date.");
  }

  if (localDateStart.isAfter(localDateEnd)) {
    throw new Error("The start date must be before the end date.");
  }

  while (localDateStart.isBefore(localDateEnd) || localDateStart.isSame(localDateEnd)) {
    dateRanges.push(localDateStart.clone());

    localDateStart = localDateStart.add(dayjs.duration(step));
  }

  return dateRanges;
}

/**
 *
 * @param dateRange The date range array.
 * @param items The items to be organised.
 * @param compareFunc The function to compare the date and the item.
 * @returns The organised items.
 * @example
 * dateRange: ["2021-01-01", "2021-01-02", "2021-01-03"]
 * items: [{ date: "2021-01-01", value: 1 }, { date: "2021-01-03", value: 3 }]
 * result: [ { date: "2021-01-01", value: 1 }, undefined, { date: "2021-01-03", value: 3 } ]
 */
function organiseItemsIntoDateRange<Item>(
  dateRange: Dayjs[],
  items: Item[],
  compareFunc: (date: Dayjs, item: Item) => boolean
): { date: Dayjs; item: Item | undefined }[] {
  return dateRange.map((date) => {
    if (!date.isValid()) {
      throw new Error("Invalid date.");
    }

    const aggregate = items.find((item) => compareFunc(date, item));

    return {
      date,
      item: aggregate,
    };
  });
}

/**
 * Check if two date ranges are overlapping.
 * @param range1 The first date range.
 * @param range1.start The start date of the first date range.
 * @param range1.end The end date of the first date range.
 * @param range2 The second date range.
 * @param range2.start The start date of the second date range.
 * @param range2.end The end date of the second date range.
 * @returns True if the date ranges are overlapping, false otherwise.
 */
function isDateTimeRangesOverlapping(
  range1: { start: Dayjs; end: Dayjs },
  range2: { start: Dayjs; end: Dayjs }
): boolean {
  // Validate the input
  z.object({
    start: z.custom(isDayJSValid),
    end: z.custom(isDayJSValid),
  }).parse(range1);
  z.object({
    start: z.custom(isDayJSValid),
    end: z.custom(isDayJSValid),
  }).parse(range2);

  return (
    (range1.start.isSameOrAfter(range2.start) && range1.start.isSameOrBefore(range2.end)) || // Start inside. e.g. range1: 1-3, range2: 2-4
    (range1.end.isSameOrAfter(range2.start) && range1.end.isSameOrBefore(range2.end)) || // End inside. e.g. range1: 1-3, range2: 0-2
    (range1.start.isSameOrBefore(range2.start) && range1.end.isSameOrAfter(range2.end)) // Encompassing range. e.g. range1: 1-3, range2: 2-3
  );
}

/**
 * Check if there are overlapping date ranges.
 * @param ranges The date ranges to check.
 * @returns True if there are overlapping date ranges, false otherwise.
 */
function checkOverlappingDatetimeRanges(ranges: { start: Dayjs; end: Dayjs }[]): boolean {
  let isOverlapping: boolean = false;

  // Validate the input
  z.array(
    z.object({
      start: z.custom(isDayJSValid),
      end: z.custom(isDayJSValid),
    })
  ).parse(ranges);

  ranges.forEach((range, index) => {
    ranges
      .filter((_, i) => i !== index)
      .forEach((otherRange) => {
        const hasOverlap = isDateTimeRangesOverlapping(range, otherRange);

        if (hasOverlap) {
          isOverlapping = true;
        }
      });
  });

  return isOverlapping;
}

export { isDateTimeRangesOverlapping, checkOverlappingDatetimeRanges, dateRangeArray, organiseItemsIntoDateRange };

<template>
  <div class="relative rounded-md shadow-sm">
    <div class="pointer-events-none absolute inset-y-0 left-0 flex flex-start items-center pl-3">
      <MagnifyingGlassIcon class="h-5 w-5 text-gray-400" />
    </div>

    <input
      :value="modelValue"
      v-bind="$attrs"
      type="search"
      class="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
      :placeholder="placeHolder"
      aria-label="Search"
      role="search"
      @input="handleInput($event)"
    />
  </div>
</template>

<script lang="ts">
import useModelValue from "@/base/composables/ModelValueComposable.ts";
import { MagnifyingGlassIcon } from "@heroicons/vue/24/outline";
import type { DebouncedFunc } from "lodash";
import { debounce } from "lodash";
import type { PropType } from "vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseSearchInput",
  components: { MagnifyingGlassIcon },
  inheritAttrs: false,

  props: {
    /**
     * HTML button type attribute
     */
    placeHolder: {
      required: false,
      type: String,
      validator: (type) => typeof type === "string",
      default: "Search...",
    },

    /** Debounce time in milliseconds */
    debounce: {
      required: false,
      type: Number as PropType<number | undefined>,
      default: 0,
      validator: (val: number) => val >= 0,
    },

    /**
     * The model value.
     */
    modelValue: {
      type: String,
      required: false,
      default: "",
    },
  },

  emits: {
    "update:modelValue": (input: string) => {
      return typeof input === "string";
    },
  },

  setup() {
    const { updateModelValue } = useModelValue();
    return {
      updateModelValue,
    };
  },

  data() {
    return {
      debounceFunction: undefined as DebouncedFunc<(event: Event) => void> | undefined,
    };
  },

  computed: {
    usesDebounce() {
      return this.debounce !== undefined && this.debounce > 0;
    },
  },

  created() {
    if (!this.usesDebounce) {
      return;
    }

    this.debounceFunction = debounce(($event: Event) => {
      this.updateModelValue(($event.target as HTMLInputElement).value);
    }, this.debounce);
  },

  methods: {
    handleInput($event: Event) {
      const input = $event.target as HTMLInputElement | null;

      /* istanbul ignore next -- @preserve */
      if (!input || !("value" in input)) {
        return;
      }

      if (this.usesDebounce && this.debounceFunction) {
        this.debounceFunction($event);

        return;
      }

      this.updateModelValue(input.value);
    },
  },
});
</script>

<template>
  <BaseFilterMenuFilter :position="position" :active-count="optionsActiveCount" :label="label">
    <BaseFilterMultiSelectOptionDropdownMenu
      :no-wrap="noWrap"
      :options="options"
      @update:options="handleUpdateOptions($event)"
    ></BaseFilterMultiSelectOptionDropdownMenu>
  </BaseFilterMenuFilter>
</template>

<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";
import type { MultiSelectFilterOption } from "@/base/components/menus/dropdownMenus/BaseFilterMultiSelectOptionDropdownMenu.vue";
import BaseFilterMultiSelectOptionDropdownMenu from "@/base/components/menus/dropdownMenus/BaseFilterMultiSelectOptionDropdownMenu.vue";
import type { FilterMenuPosition } from "@/base/components/menus/filterMenus/filters/BaseFilterMenuFilter.vue";
import BaseFilterMenuFilter from "@/base/components/menus/filterMenus/filters/BaseFilterMenuFilter.vue";
import useModelValue from "@/base/composables/ModelValueComposable.ts";
import { ClickAway } from "@/base/directives/ClickAwayDirective.ts";

export default defineComponent({
  name: "BaseFilterMenuMultiSelectFilter",

  components: {
    BaseFilterMultiSelectOptionDropdownMenu,
    BaseFilterMenuFilter,
  },

  directives: {
    ClickAway,
  },

  props: {
    /** Filter options */
    options: {
      type: Array as PropType<MultiSelectFilterOption[]>,
      required: true,
    },

    /** Filter menu position in filter list */
    position: {
      type: String as PropType<FilterMenuPosition>,
      required: true,
    },

    /** The filter label */
    label: {
      type: String as PropType<string>,
      required: true,
    },

    /**
     * Whether the dropdown menu items should not wrap its text.
     */
    noWrap: {
      type: Boolean as PropType<boolean | undefined>,
      required: false,
      default: false,
    },
  },

  emits: {
    "update:options": (options: MultiSelectFilterOption[]) => Array.isArray(options),
  },

  setup() {
    const { updateModelValue: updateOptions } = useModelValue<MultiSelectFilterOption[]>("options");

    return {
      updateOptions,
    };
  },

  computed: {
    optionsActiveCount() {
      const count = this.options.filter((option) => option.value).length;

      if (count === 0) return undefined;

      return count;
    },
  },

  methods: {
    handleUpdateOptions(options: MultiSelectFilterOption[]) {
      this.updateOptions(options);
    },
  },
});
</script>

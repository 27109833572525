<template>
  <div :class="mainCssClass">
    <div :class="cardCssClass">
      <dt>
        <div v-if="!hideIcon" class="absolute rounded-md bg-blue-500 flex items-center justify-center h-12 w-12">
          <FontAwesomeIcon
            :icon="statIcon"
            class="text-white"
            :class="
              iconSize &&
              {
                lg: 'text-lg',
                xl: 'text-xl',
              }[iconSize]
            "
          />
        </div>

        <p class="truncate text-sm font-medium text-gray-500" :title="title" :class="{ 'ml-16': !hideIcon }">
          {{ title }}
        </p>
      </dt>

      <dd class="flex items-baseline pb-6 sm:pb-7" :class="{ 'ml-16': !hideIcon }">
        <p class="text-2xl font-semibold text-gray-900">{{ stat }}</p>

        <p v-if="showDelta" class="ml-2 flex items-baseline text-sm font-semibold aspect-square" :class="deltaCssClass">
          <FontAwesomeIcon :icon="deltaIconToUse" class="mr-1" size="xl" />

          <span v-if="deltaDirection === 'level'" class="sr-only">Value remained level</span>

          <span v-else class="sr-only"> Went {{ deltaDirection }} by </span>
          {{ delta }}
        </p>

        <div v-if="showViewAll" class="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
          <div class="text-sm">
            <button type="button" href="#" class="font-medium text-blue-600 hover:text-blue-500">
              {{ viewAllText }}<span class="sr-only">{{ stat }} stats</span>
            </button>
          </div>
        </div>
      </dd>
    </div>
  </div>
</template>

<script lang="ts">
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUsers } from "@fortawesome/pro-light-svg-icons";
import { faArrowDown, faArrowUp, faHyphen } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import type { PropType } from "vue";
import { defineComponent } from "vue";

library.add(faUsers, faArrowUp, faArrowDown, faHyphen);

export default defineComponent({
  name: "BaseStatCard",
  components: {
    FontAwesomeIcon,
  },

  props: {
    /** the stat name/title */
    title: {
      required: true,
      type: String,
    },

    /** the actual stat */
    stat: {
      required: true,
      type: String,
    },

    /** Size of the stat icon */
    iconSize: {
      type: String as PropType<"lg" | "xl">,
      required: false,
      default: undefined,
    },

    /** the stat icon, icon will need to be loaded in parent component if changed */
    statIcon: {
      required: false,
      type: Array,
      default: () => ["fal", "users"],
    },

    /** show the delta */
    showDelta: {
      required: false,
      type: Boolean,
    },

    /** the actual delta stat */
    delta: {
      required: false,
      type: String,
      default: "0",
    },

    /**
     * the comparison stat direction which determines if the stat increased, decreased or stayed level, up, down, level
     */
    deltaDirection: {
      required: false,
      type: String,
      default: "level",
      validator: (value: string) => {
        return ["up", "down", "level"].includes(value);
      },
    },

    /** the comparison stat descriptor which defines whether the stat is green red or yellow, good, bad, level */
    deltaDescription: {
      required: false,
      type: String,
      default: "level",
      validator: (value: string) => {
        return ["good", "bad", "level"].includes(value);
      },
    },

    /** change the column size of the component */
    colSize: {
      required: false,
      type: Number,
      default: 1,
    },

    /** show the view all button */
    showViewAll: {
      required: false,
      type: Boolean,
    },

    /** change the view all text */
    viewAllText: {
      required: false,
      type: String,
      default: "View all",
    },

    /** hide the icon */
    hideIcon: {
      required: false,
      type: Boolean,
    },
  },

  computed: {
    deltaCssClass() {
      switch (this.deltaDescription) {
        case "good":
          return "text-green-600";
        case "bad":
          return "text-red-600";
        default:
          return "text-yellow-600";
      }
    },

    deltaIconToUse() {
      switch (this.deltaDirection) {
        case "up":
          return ["fas", "arrow-up"];
        case "down":
          return ["fas", "arrow-down"];
        default:
          return ["fas", "hyphen"];
      }
    },

    cardCssClass() {
      let base = "relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6";
      if (this.showViewAll) {
        base += " pb-12";
      }
      return base;
    },

    mainCssClass() {
      let base = "mt-5 gap-5";
      switch (this.colSize) {
        case 1:
          base += " col-span-1";
          break;
        case 2:
          base += " col-span-2";
          break;
        case 3:
          base += " col-span-3";
          break;
        case 4:
          base += " col-span-4";
          break;

        default:
          base += " col-span-5";
          break;
      }
      return base;
    },
  },
});
</script>

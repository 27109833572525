<template>
  <ol
    class="rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
    role="menu"
    aria-orientation="vertical"
    tabindex="0"
  >
    <template v-for="(item, index) in items">
      <BaseDropdownMenuItem
        v-if="item.type === 'button'"
        :key="index"
        :label="item.label"
        :no-wrap="noWrap"
        @click="handleClick(item, index)"
      ></BaseDropdownMenuItem>

      <router-link v-if="item.type === 'link'" v-slot="{ isActive, navigate }" :key="index" :to="item.to" custom>
        <BaseDropdownMenuItem
          :label="item.label"
          :active="isActive"
          :no-wrap="noWrap"
          @click="
            navigate();
            handleClick(item, index);
          "
        ></BaseDropdownMenuItem
      ></router-link>
    </template>
  </ol>
</template>

<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";
import BaseDropdownMenuItem from "@/base/components/menus/dropdownMenus/items/BaseDropdownMenuItem.vue";
import type { BaseDropdownMenuButtonItem } from "@/base/interfaces/menus/dropdownMenus/BaseDropdownMenuButtonItem";
import type { BaseDropdownMenuLinkItem } from "@/base/interfaces/menus/dropdownMenus/BaseDropdownMenuLinkItem";

export default defineComponent({
  name: "BaseDropdownMenu",

  components: {
    BaseDropdownMenuItem,
  },

  props: {
    /**
     * Top level menu items to be included in the menu.
     */
    items: {
      type: Array as PropType<(BaseDropdownMenuButtonItem | BaseDropdownMenuLinkItem)[]>,
      required: true,
    },

    /**
     * Whether the dropdown menu items should not wrap its text.
     */
    noWrap: {
      type: Boolean as PropType<boolean | undefined>,
      required: false,
      default: false,
    },
  },

  emits: {
    click: (item: BaseDropdownMenuButtonItem | BaseDropdownMenuLinkItem, index: number) =>
      typeof item === "object" && typeof index === "number",
  },

  methods: {
    handleClick(item: BaseDropdownMenuButtonItem | BaseDropdownMenuLinkItem, index: number) {
      this.$emit("click", item, index);
    },
  },
});
</script>

<template>
  <BaseDatePagination
    :model-value="modelValue"
    interval-type="day"
    :interval-size="7"
    :format="getDatePaginationFormat"
    :debounce="debounce"
    :disable-next="disableNext"
    :no-initial-emit="noInitialEmit"
    @update:model-value="
      (...args) => {
        $emit('update:modelValue', ...args);
      }
    "
  >
  </BaseDatePagination>
</template>

<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";
import BaseDatePagination from "@/base/components/pagination/BaseDatePagination.vue";
import type { Dayjs } from "dayjs";
import { isDayjs } from "dayjs";
import useContextDateTimeZoneComposable from "@/date/composables/ContextDateTimeZoneComposable.ts";

export default defineComponent({
  name: "BaseWeekPagination",

  components: {
    BaseDatePagination,
  },

  props: {
    /** Start of the week */
    modelValue: {
      type: Object as PropType<Dayjs>,
      required: true,
      validator: isDayjs,
    },

    /** Debounce date navigation time in milliseconds */
    debounce: {
      required: false,
      type: Number as PropType<number | undefined>,
      default: 0,
      validator: (val: number) => val >= 0,
    },

    /** Disable next navigation */
    disallowFutureWeeks: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },

    /**
     * Instructs the component to emit the bounds on create.
     */
    noInitialEmit: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },

  emits: {
    "update:modelValue": (startDate: Dayjs, endDate: Dayjs) => isDayjs(startDate) && isDayjs(endDate),
  },

  setup() {
    const { getTodayInContextTimeZone } = useContextDateTimeZoneComposable();
    const today = getTodayInContextTimeZone();

    return {
      now: today.clone(),
      startOfWeek: today.clone().startOf("isoWeek"),
    };
  },

  methods: {
    disableNext(startDate: Dayjs) {
      if (!this.disallowFutureWeeks) {
        return false;
      }

      return startDate.isSame(this.startOfWeek);
    },

    getDatePaginationFormat(startDate: Dayjs) {
      return startDate.isSame(this.startOfWeek) ?
          {
            exact: "This Week",
          }
        : {
            both: `D MMM${startDate.year() !== this.now.year() ? " YYYY" : /* istanbul ignore next -- @preserve */ ""}`,
          };
    },
  },
});
</script>

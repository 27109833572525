<template>
  <div class="flex flex-col">
    <BaseNavMenuButton
      html-role="button"
      :label="label"
      expandable
      :expanded="internalExpanded"
      :active="active"
      :without-icon="withoutIcon"
      @click="handleClick()"
    >
      <template #icon="iconSlotProps">
        <slot name="icon" v-bind="iconSlotProps"></slot>
      </template>
    </BaseNavMenuButton>

    <div
      ref="list"
      role="list"
      class="pl-11 text-sm space-y-3 font-normal invisible opacity-0 flex flex-col h-0 transition-all ease-in-out duration-600"
    >
      <BaseNavMenuSubItemLinkList :sub-items="items"></BaseNavMenuSubItemLinkList>
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";
import { useLink } from "vue-router";
import BaseNavMenuButton from "@/base/components/buttons/BaseNavMenuButton.vue";
import BaseNavMenuSubItemLinkList from "@/base/components/menus/navMenus/subitems/BaseNavMenuSubItemLinkList.vue";
import type { BaseNavMenuSubItem } from "@/base/interfaces/menus/navMenus/BaseNavMenuSubItem.ts";

export default defineComponent({
  name: "BaseNavMenuDropdownItem",

  components: {
    BaseNavMenuButton,
    BaseNavMenuSubItemLinkList,
  },

  props: {
    /**
     * Label for the top level nav button
     */
    label: {
      type: String as PropType<string>,
      required: true,
    },

    /**
     * Items in this nav item
     */
    items: {
      type: Array as PropType<BaseNavMenuSubItem[]>,
      required: true,
      validator: (items: BaseNavMenuSubItem[]): boolean => items.length > 0,
    },

    /**
     * A parent override for expanding the element.
     */
    expanded: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },

    /** Whether to hide the icon */
    withoutIcon: {
      type: [Boolean, undefined] as PropType<boolean | undefined>,
      required: false,
      default: false,
    },
  },

  emits: {
    updateExpanded: (expanded: boolean) => typeof expanded === "boolean",
  },

  setup(props) {
    const activeLinks = props.items.map((link) => useLink({ to: link.to }).isActive);

    return {
      activeLinks,
      listActiveClassList: ["h-[100%]", "pt-3", "pb-3"],

      listInactiveClassList: ["h-0", "invisible", "opacity-0"],
    };
  },

  data() {
    return {
      internalExpanded: this.expanded,
    };
  },

  computed: {
    active() {
      return this.activeLinks.some((isActive) => isActive.value);
    },
  },

  watch: {
    expanded(value: boolean): void {
      this.internalExpanded = value;
      this.toggleExpand();
    },
  },

  methods: {
    toggleExpand() {
      const { list: listRef } = this.$refs;

      const list: HTMLElement = listRef as HTMLElement;

      if (this.internalExpanded) {
        this.listInactiveClassList.forEach((className) => {
          list.classList.remove(className);
        });

        this.listActiveClassList.forEach((className) => {
          list.classList.add(className);
        });

        return;
      }

      this.listInactiveClassList.forEach((className) => {
        list.classList.add(className);
      });

      this.listActiveClassList.forEach((className) => {
        list.classList.remove(className);
      });
    },

    handleClick() {
      this.internalExpanded = !this.internalExpanded;

      this.$emit("updateExpanded", this.internalExpanded);

      this.toggleExpand();
    },
  },
});
</script>

/* eslint-disable import/prefer-default-export */
import type User from "@/auth/interfaces/User.ts";

/**
 * The user for Canny
 * @param user  The user to set in Canny
 */
export function setCannyUser(user: User): void {
  window.Canny("identify", {
    appID: import.meta.env["VITE_CANNY_APP_ID"],
    user: {
      companies: [
        ...(typeof user.current_company_id === "number" ?
          [
            {
              id: user.current_company_id,
              name: user.current_company_name,
            },
          ]
        : []),
        ...(typeof user.current_site_id === "number" ?
          [
            {
              id: user.current_site_id,
              name: user.current_site_name,
            },
          ]
        : []),
      ],
      email: user.email,
      id: user.id,
      name: user.full_name,
    },
  });
}

/**
 * Boot the Canny changelog
 */
export function bootCannyChangelog(): void {
  window.Canny("initChangelog", {
    appID: import.meta.env["VITE_CANNY_APP_ID"],
    position: "top",
    align: "left",
    theme: "light", // options: light [default], dark, auto
  });
}

import { Dayjs, isDayjs } from "dayjs";
import { PropType } from "vue";

const requiredDayJsProp = {
  type: Object as PropType<Dayjs>,
  required: true as const,
  validator: isDayjs,
};
const requiredNullableDayJsProp = {
  type: [Object, null] as PropType<Dayjs | null>,
  required: true as const,
  validator: (value: Dayjs | null) => value === null || isDayjs(value),
};

const optionalDayJsProp = {
  type: Object as PropType<Dayjs | undefined>,
  required: false as const,
  validator: isDayjs,
};

const optionalNullableDayJsProp = {
  type: [Object, null] as PropType<Dayjs | null | undefined>,
  required: false as const,
  validator: isDayjs,
};

export { requiredDayJsProp, optionalNullableDayJsProp, requiredNullableDayJsProp, optionalDayJsProp };

<template>
  <BaseAvatar :avatar="computedUser?.avatar" :name="computedUser.full_name"> </BaseAvatar>
</template>

<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";
import useAuthenticationStore from "@/auth/stores/AuthenticationStore.ts";
import type User from "@/auth/interfaces/User";
import BaseAvatar from "@/base/components/avatars/BaseAvatar.vue";

export default defineComponent({
  name: "UserAvatar",

  components: {
    BaseAvatar,
  },

  props: {
    /** Optional user to override the current */
    user: {
      type: [Object, null, undefined] as PropType<Pick<User, "avatar" | "full_name"> | null | undefined>,
      required: false,
      default: undefined,
    },
  },

  setup() {
    const authStore = useAuthenticationStore();

    return {
      authStore,
    };
  },

  computed: {
    computedUser() {
      return this.user ?? this.authStore.findAuthenticatedUserOrFail();
    },
  },
});
</script>

<template>
  <img
    :class="{
      'h-12': size === 'screen',
      'h-8': size === 'layout',
    }"
    :src="getVaporAssetUrl('resources/images/base/logos/core-app-logo.png')"
    alt="Core App Logo"
    class="w-auto"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import useVaporAsset from "@/base/composables/VaporAssetComposable.ts";

/**
 * The Core App Logo component.
 *
 * Displays the world's most amazing logo.
 */
export default defineComponent({
  name: "CoreAppLogo",

  props: {
    /** The type of logo size to display */
    size: {
      required: false,
      validator: (value: string) => ["screen", "layout"].includes(value),
      default: "layout",
    },
  },

  setup() {
    const { getVaporAssetUrl } = useVaporAsset();

    return {
      getVaporAssetUrl,
    };
  },
});
</script>

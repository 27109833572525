<!-- eslint-disable vuejs-accessibility/label-has-for Provided by BaseFormInput -->
<template>
  <!-- #region Inline layout -->
  <div
    v-if="layoutOptions.type === 'inline'"
    class="sm:grid sm:grid-cols-12 sm:items-start sm:gap-4 py-4 sm:py-6"
    :class="[field.class, layoutOptions.noHorizontalPadding !== true && 'px-4  sm:px-6']"
    data-testid="inline-layout-form-input"
  >
    <label
      :class="{ 'sr-only': hideInlineLabel }"
      class="block text-sm sm:col-span-4 font-medium leading-6 text-gray-900 sm:pt-1.5"
      data-testid="inline-layout-input-label"
    >
      {{ field.label }}
    </label>

    <div
      :class="{
        'sm:col-span-8': !hideInlineLabel,
        'sm:col-span-12': hideInlineLabel,
        [field.class]: true,
      }"
      class="mt-2 sm:mt-0 h-full"
    >
      <slot :hide-input-label="hideInputLabel" />
    </div>
  </div>
  <!-- #endregion -->

  <!-- #region Stacked layout -->
  <div v-else-if="layoutOptions.type === 'stacked'" :class="field.class" data-testid="stacked-layout-form-input">
    <slot :hide-input-label="hideInputLabel" />
  </div>
  <!-- #endregion -->

  <!-- #region Horizontal layout -->
  <div v-else data-testid="horizontal-layout-form-input" class="px-2 py-4 sm:py-6">
    <slot :hide-input-label="hideInputLabel" />
  </div>
</template>

<script lang="ts">
import type { PropType, SlotsType } from "vue";
import { defineComponent } from "vue";
import type { FormField } from "./BaseFormInterface";

export interface InlineLayoutOptions {
  type: "inline";
  noHorizontalPadding?: boolean;
  noBorder?: boolean;
  noLabels?: boolean;
  noSections?: boolean;
}

export interface StackedLayoutOptions {
  type: "stacked";
  noBorder?: boolean;
  noSections?: boolean;
  noHorizontalPadding?: boolean;
  inlineSections?: boolean;
}

export interface HorizontalLayoutOptions {
  type: "horizontal";
  noSections?: boolean;
}

export type LayoutOptions = HorizontalLayoutOptions | InlineLayoutOptions | StackedLayoutOptions;

export default defineComponent({
  name: "BaseFormInputLayout",

  props: {
    /** The form field to render */
    field: {
      type: Object as PropType<FormField>,
      required: true,
    },

    /** The form layout options */
    layoutOptions: {
      type: Object as PropType<LayoutOptions>,
      required: true,
    },
  },

  slots: Object as SlotsType<{ default: { hideInputLabel: boolean } }>,

  computed: {
    hideInlineLabel() {
      if (this.field.hideLabel !== undefined) {
        return this.field.hideLabel;
      }

      return this.layoutOptions.type === "inline" && this.layoutOptions.noLabels === true;
    },

    hideInputLabel() {
      // Don't hide labels for checkboxes as it does not make sense.
      if (this.field.type === "checkbox") {
        return false;
      }

      if (this.layoutOptions.type === "inline" || this.layoutOptions.type === "horizontal") {
        return true;
      }

      if (this.field.hideLabel !== undefined) {
        return this.field.hideLabel;
      }

      return false;
    },
  },
});
</script>

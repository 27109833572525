<template>
  <div class="bg-white rounded-lg shadow-md p-4">
    <h3 class="text-lg font-semibold mb-5" :class="titleClassList">{{ title }}</h3>

    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseCard",

  props: {
    /** The title of the card */
    title: {
      required: true,
      type: String,
    },

    /** Title class list */
    titleClassList: {
      required: false,
      type: [String, Array],
      default: undefined,
    },
  },
});
</script>

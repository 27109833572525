import { isDayJSValid } from "@/validation/zod/custom/ZodDayJS.ts";
import { Dayjs } from "dayjs";
import { z } from "zod";

/**
 * Compare and reduce an array of dayjs.
 * @param values The values to compare.
 * @param comparatorMethod The method to compare the values.
 * @returns The reduced moment.
 * @throws {Error} When there are no values to compare.
 */
function compareReduceDayJSArray(values: Dayjs[], comparatorMethod: "isAfter" | "isBefore"): Dayjs {
  z.array(z.custom(isDayJSValid)).parse(values);

  let current = values[0];

  if (current === undefined) {
    throw new Error("There are no values to compare");
  }

  values.splice(0, 1);

  values.forEach((value) => {
    if (value[comparatorMethod](current)) current = value;
  });

  return current.clone();
}

/**
 * Get the maximum moment from an array of dayjs.
 * @param values The values to compare.
 * @returns The maximum moment.
 */
export function maxDayJS(values: Dayjs[]): Dayjs {
  return compareReduceDayJSArray(values, "isAfter");
}

/**
 * Get the minimum moment from an array of dayjs.
 * @param values The values to compare.
 * @returns The minimum dayjs.
 */
export function minDayJS(values: Dayjs[]) {
  return compareReduceDayJSArray(values, "isBefore");
}

<template>
  <router-link v-slot="{ navigate }" :to="to" custom>
    <BaseCardButton
      :title="title as string"
      :description="description as string"
      :disabled="disabled"
      role="link"
      @click="navigate()"
    >
    </BaseCardButton>
  </router-link>
</template>

<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";
import type { RouteLocationRaw } from "vue-router";
import BaseCardButton, { Props as BaseCardButtonProps } from "@/base/components/buttons/BaseCardButton.vue";

export default defineComponent({
  name: "BaseCardRouterLink",

  components: {
    BaseCardButton,
  },

  props: {
    ...BaseCardButtonProps,

    /**
     * The `RouteRecordRaw` prop used in the `RouterLink` component for navigation
     */
    to: {
      required: true,
      type: [Object, String] as PropType<RouteLocationRaw>,
    },
  },
});
</script>

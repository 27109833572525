<template>
  <div class="flex justify-center">
    <BaseShowMoreButton v-if="hasMorePages" @click="handleShowMoreButtonClick()" />

    <p v-else class="text-gray-500 text-sm">End of results</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BaseShowMoreButton from "./BaseShowMoreButton.vue";

/**
 * @todo abstract pagination code so that it can be used in multiple components
 */
export default defineComponent({
  name: "BaseShowMorePaginator",
  components: {
    BaseShowMoreButton,
  },

  props: {
    /**
     * The current page
     */
    currentPage: {
      type: Number,
      required: true,
    },

    /**
     * The last page
     */
    lastPage: {
      type: Number,
      required: true,
    },
  },

  emits: {
    "update:currentPage": (_page: number) => true,
  },

  computed: {
    /**
     * Whether there are more pages
     * @returns whether there are more pages
     */
    hasMorePages(): boolean {
      return this.currentPage < this.lastPage;
    },

    /**
     * The next page number
     * @returns the next page number
     */
    nextPage(): number {
      return this.currentPage + 1;
    },
  },

  methods: {
    /**
     * Handles the show more button click
     */
    handleShowMoreButtonClick(): void {
      this.$emit("update:currentPage", this.nextPage);
    },
  },
});
</script>

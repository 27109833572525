<template>
  <div class="lg:border-b lg:border-t lg:border-gray-200">
    <nav aria-label="Progress">
      <ol class="overflow-hidden rounded-md lg:flex lg:rounded-none lg:border-l lg:border-r lg:border-gray-200">
        <li v-for="(step, stepIdx) in steps" :key="step.key" class="relative overflow-hidden lg:flex-1">
          <div
            class="overflow-hidden border border-gray-200 lg:border-0"
            :class="[
              stepIdx === 0 ? 'rounded-t-md border-b-0' : '',
              stepIdx === steps.length - 1 ? 'rounded-b-md border-t-0' : '',
            ]"
            data-testid="step-border"
          >
            <!-- region Completed Step -->
            <button v-if="step.status === 'completed'" type="button" class="group" @click="handleStepClick(step)">
              <span
                class="absolute left-0 top-0 h-full w-1 bg-transparent group-hover:bg-gray-200 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                aria-hidden="true"
              ></span>

              <span
                class="flex items-start px-6 py-5 text-sm font-medium"
                :class="[stepIdx !== 0 ? 'lg:pl-9' : '']"
                data-testid="completed-step-container"
              >
                <span class="flex-shrink-0">
                  <span class="flex h-10 w-10 items-center justify-center rounded-full bg-blue-600">
                    <FontAwesomeIcon
                      class="text-white"
                      size="lg"
                      :icon="['fas', 'check']"
                      title="Check"
                      aria-hidden="true"
                    />
                  </span>
                </span>

                <span class="ml-4 mt-0.5 flex min-w-0 flex-col text-left">
                  <span class="text-sm font-medium">{{ step.title }}</span>

                  <span class="text-sm font-medium text-gray-500">{{ step.description }}</span>
                </span>
              </span>
            </button>
            <!-- endregion -->

            <!-- region Current Step -->
            <button
              v-else-if="step.status === 'current'"
              type="button"
              aria-current="step"
              @click="handleStepClick(step)"
            >
              <span
                class="absolute left-0 top-0 h-full w-1 bg-blue-600 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                aria-hidden="true"
              ></span>

              <span
                class="flex items-start px-6 py-5 text-sm font-medium"
                :class="[stepIdx !== 0 ? 'lg:pl-9' : '']"
                data-testid="current-step-container"
              >
                <span class="flex-shrink-0">
                  <span class="flex h-10 w-10 items-center justify-center rounded-full border-2 border-blue-600">
                    <span class="text-blue-600">{{ step.displayStepNumber }}</span>
                  </span>
                </span>

                <span class="ml-4 mt-0.5 flex min-w-0 flex-col text-left">
                  <span class="text-sm font-medium text-blue-600">{{ step.title }}</span>

                  <span class="text-sm font-medium text-gray-500">{{ step.description }}</span>
                </span>
              </span>
            </button>
            <!-- endregion -->

            <!-- region Upcoming Step -->
            <button v-else type="button" class="group" @click="handleStepClick(step)">
              <span
                class="absolute left-0 top-0 h-full w-1 bg-transparent group-hover:bg-gray-200 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                aria-hidden="true"
              ></span>

              <span
                class="flex items-start px-6 py-5 text-sm font-medium"
                :class="[stepIdx !== 0 ? 'lg:pl-9' : '']"
                data-testid="upcoming-step-container"
              >
                <span class="flex-shrink-0">
                  <span class="flex h-10 w-10 items-center justify-center rounded-full border-2 border-gray-300">
                    <span class="text-gray-500">{{ step.displayStepNumber }}</span>
                  </span>
                </span>

                <span class="ml-4 mt-0.5 flex min-w-0 flex-col text-left">
                  <span class="text-sm font-medium text-gray-500">{{ step.title }}</span>

                  <span class="text-sm font-medium text-gray-500">{{ step.description }}</span>
                </span>
              </span>
            </button>
            <!-- endregion -->

            <template v-if="stepIdx !== 0">
              <!-- Separator -->
              <div class="absolute inset-0 left-0 top-0 hidden w-3 lg:block" aria-hidden="true" data-testid="separator">
                <svg class="h-full w-full text-gray-300" viewBox="0 0 12 82" fill="none" preserveAspectRatio="none">
                  <path d="M0.5 0V31L10.5 41L0.5 51V82" stroke="currentcolor" vector-effect="non-scaling-stroke" />
                </svg>
              </div>
            </template>
          </div>
        </li>
      </ol>
    </nav>
  </div>
</template>

<script lang="ts">
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import type { PropType } from "vue";
import { defineComponent } from "vue";

library.add(faCheck);

export interface Step {
  /** The step title */
  title: string;
  /** The step description */
  description: string;
  /** The step number to display */
  displayStepNumber: string;
  /** The step identifier. Must be unique */
  key: string;
  /** The step status */
  status: "completed" | "current" | "upcoming";
}

export default defineComponent({
  name: "BaseSteps",

  components: {
    FontAwesomeIcon,
  },

  props: {
    /** The steps to display */
    steps: {
      type: Array as PropType<Step[]>,
      required: true,
    },
  },

  emits: {
    clickStep: (_step: Step) => true,
  },

  methods: {
    handleStepClick(step: Step) {
      this.$emit("clickStep", step);
    },
  },
});
</script>

<template>
  <label :for="id" class="block" data-testid="select-label" :class="labelClass">
    <div
      v-bind="{
        ...(hideLabel ? { class: 'sr-only' } : {}),
      }"
    >
      {{ label }}
    </div>

    <div class="rounded-md shadow-sm" :class="{ 'mt-2': !hideLabel }">
      <select v-model="localSelectedOptionIndex" v-bind="attributes" @change="onChange($event)">
        <option
          v-for="(option, index) in mappedOptions"
          :key="index"
          :ref="`option-${index}`"
          :value="index"
          :aria-label="getOptionLabel(option)"
        >
          {{ getOptionLabel(option) }}
        </option>

        <option v-if="options.length === 0" disabled selected :value="undefined">No options available</option>
      </select>
    </div>
  </label>
  <!-- Error message -->
  <p
    v-show="hasErrorMessage"
    :id="errorId"
    :aria-hidden="!hasErrorMessage"
    class="mt-2 font-normal text-sm text-red-600"
  >
    {{ errorMessage }}
  </p>
</template>

<script lang="ts">
import { labelKeyProp, useAggregateInput, valueKeyProp } from "@/base/composables/inputs/AggregateInputComposable.ts";
import useModelValue from "@/base/composables/ModelValueComposable.ts";
import assertStringIsNotBlank from "@/base/functions/asserts/strings/AssertStringIsNotBlank.ts";
import type { PropType } from "vue";
import { defineComponent, toRef } from "vue";

type OptionValue = Readonly<unknown> | boolean | number | object | string | [] | null | undefined;
type Options = OptionValue[] | Readonly<Readonly<OptionValue>[]> | Record<string, OptionValue>;

export type { OptionValue };

/**
 * @todo Refactor to support generics
 */
export default defineComponent({
  name: "BaseSelectInput",

  inheritAttrs: false,
  props: {
    /**
     * Options to be used in the select.
     */
    options: {
      type: [Object, Array] as PropType<Options>,
      required: true,
    },

    /**
     * How the select input should access the option label
     */
    labelKey: labelKeyProp,

    /**
     * How the select input should access the option value
     */
    valueKey: valueKeyProp,

    /**
     * The current value
     */
    modelValue: {
      type: [Object, String, Number, Boolean, Array, null] as PropType<OptionValue>,
      required: false,
      default: undefined,
    },

    /**
     * Id HTML attribute
     */
    id: {
      required: true,
      type: String,
      validator: (id) => assertStringIsNotBlank(id),
    },

    /**
     * Input label text
     */
    label: {
      required: true,
      type: String,
      validator: (label) => assertStringIsNotBlank(label),
    },

    /**
     * Whether to hide the label
     */
    hideLabel: {
      required: false,
      type: Boolean,
    },

    /**
     * Error message to render with the input
     *
     * Should only be used if there is a error to display
     */
    errorMessage: {
      required: false,
      type: String as PropType<string | undefined>,
      default: undefined,
    },

    /**
     * Id HTML attribute for error message
     */
    errorId: {
      required: false,
      type: String,
      default: "error",
      validator: (id) => assertStringIsNotBlank(id),
    },

    /**
     * HTML attribute to prevent browser interaction
     */
    disabled: {
      required: false,
      type: Boolean,
    },

    /**
     * Class for the field label.
     */
    labelClass: {
      type: String,
      required: false,
      default: "text-sm font-medium text-gray-700",
    },
  },

  emits: {
    "update:modelValue": (_value: OptionValue, _option: unknown) => true,
  },

  setup(props) {
    const {
      isOptionSelected,
      getOptionLabel,
      getOptionValue,
      mappedOptions,
      selectedOptionIndex,
      selectedOptionValue,
    } = useAggregateInput(toRef(props, "options"), props.valueKey, props.labelKey, toRef(props, "modelValue"));
    const { updateModelValue } = useModelValue();

    return {
      isOptionSelected,
      getOptionLabel,
      getOptionValue,
      selectedOptionIndex,
      selectedOptionValue,
      mappedOptions,
      updateModelValue,
    };
  },

  data() {
    return {
      localSelectedOptionIndex: undefined as number | undefined,
    };
  },

  computed: {
    attributes() {
      return {
        ...this.$attrs,
        id: this.id,
        class: ["class" in this.$attrs ? this.$attrs["class"] : "", this.inputClass, this.errorInputClass] as string[],
        "aria-invalid": this.hasErrorMessage,
        "aria-describedby": this.errorId,
        ...(this.disabled ? { disabled: this.disabled } : {}),
      };
    },

    hasErrorMessage() {
      return this.errorMessage !== undefined;
    },

    inputClass() {
      return "disabled:cursor-not-allowed disabled:bg-gray-50 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6";
    },

    errorInputClass() {
      return this.hasErrorMessage ?
          "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-inset focus:ring-red-500"
        : "text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-600";
    },
  },

  watch: {
    selectedOptionIndex: {
      immediate: true,
      handler() {
        this.localSelectedOptionIndex = this.selectedOptionIndex;
      },
    },
  },

  methods: {
    onChange(event: Event) {
      if (this.disabled) {
        return;
      }

      /* istanbul ignore if -- @preserve */
      if (event.target == null) {
        throw new TypeError("Event target is null.");
      }

      const eventTarget = event.target as EventTarget & HTMLSelectElement;
      const optionIndex: number = Number.parseInt(eventTarget.value, 10);

      /* istanbul ignore if -- @preserve */
      if (Number.isNaN(optionIndex)) {
        throw new TypeError("Select value must be option index.");
      }

      const option = this.mappedOptions[optionIndex];
      const value = this.getOptionValue(option);

      this.updateModelValue(value);
    },
  },
});
</script>

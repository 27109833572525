<template>
  <div
    class="media-gallery"
    data-testid="media-gallery"
    v-bind="
      previewLayout.type === 'grid' && {
        role: previewLayout.type,
      }
    "
    :class="[
      previewLayout.type === 'inlineWrap' && 'flex gap-4 flex-wrap',
      previewLayout.type === 'grid' && containerGridLayoutClassList,
    ]"
  >
    <div
      v-for="mediaItem in getMediaWithPreview()"
      :key="getMediaItemKey(mediaItem)"
      class="relative group"
      v-bind="
        previewLayout.type === 'grid' && {
          role: 'cell',
        }
      "
    >
      <a
        :href="getMediaItemUri(mediaItem)"
        target="_blank"
        rel="noopener noreferrer"
        :title="getMediaItemFileName(mediaItem)"
      >
        <span class="sr-only">{{ getMediaItemFileName(mediaItem) }}</span>

        <!-- @todo replace thumb_url with srcset -->
        <img
          :class="imageLayoutClassList"
          class="object-cover object-center rounded-md"
          :v-bind="{
            loading: 'lazy',
          }"
          :aria-label="getMediaItemBaseName(mediaItem)"
          :alt="getMediaItemBaseName(mediaItem)"
          :src="getMediaItemPreviewUri(mediaItem)"
        />
      </a>

      <button
        type="button"
        :aria-hidden="isReadOnly || disabled"
        title="Click to remove"
        :class="!isReadOnly && !disabled && 'group-hover:block'"
        class="absolute hidden top-0 right-0 p-1"
        :disabled="disabled || isReadOnly"
        @click="removeMedia(mediaItem)"
      >
        <span class="sr-only">Click to remove {{ getMediaItemFileName(mediaItem) }}</span>

        <FontAwesomeIcon class="h-4 w-4 text-white fill-black bg-black rounded-full" :icon="['fas', 'xmark-circle']" />
      </button>
    </div>

    <slot name="preview-item" :class-list="imageLayoutClassList"></slot>
  </div>

  <div
    v-if="getMediaWithoutPreview().length"
    class="flex gap-2 flex-col"
    :class="getMediaWithPreview().length > 0 && 'mt-4'"
  >
    <component
      :is="!isReadOnly ? 'button' : 'a'"
      v-for="mediaItem in getMediaWithoutPreview()"
      :key="getMediaItemKey(mediaItem)"
      v-bind="isReadOnly && { href: getMediaItemUri(mediaItem), target: '_blank', rel: 'noopener noreferrer' }"
      :title="getMediaItemFileName(mediaItem)"
      class="group flex items-center overflow-hidden whitespace-nowrap hover:cursor-pointer hover:underline text-blue-600"
      type="button"
      :disabled="!isReadOnly && disabled"
      @click="removeMedia(mediaItem)"
    >
      <span class="max-w-md truncate text-sm">
        {{ getMediaItemFileName(mediaItem) }}
      </span>

      <FontAwesomeIcon
        :aria-hidden="isReadOnly || disabled"
        :class="!isReadOnly && !disabled && 'group-hover:inline-block'"
        class="ml-1 h-4 w-4 shrink-0 hidden"
        :icon="['fas', 'xmark']"
      />
    </component>
  </div>
</template>

<script lang="ts">
import MediaSchema from "@/media/schemas/Media.ts";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPaperclip as fasPaperclip,
  faXmark as fasXmark,
  faXmarkCircle as fasXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { omit } from "lodash";
import type { PropType } from "vue";
import { defineComponent } from "vue";
import useMimeTypes from "../composables/MimeTypesComposable.ts";
import type Media from "../interfaces/Media";
import type VaporMedia from "../interfaces/VaporMediaInterface.ts";
import type VaporStoreResponse from "../interfaces/VaporStoreResponseInterface";
import VaporMediaSchema from "../schemas/VaporMediaSchema.ts";

library.add(fasXmark);
library.add(fasXmarkCircle);
library.add(fasPaperclip);

type MediaItem =
  | (Media & {
      type: "media";
    })
  | (VaporMedia & {
      type: "vapor";
    });

/** Create as object to allow more options */
export type PreviewMediaLayout =
  | {
      type: "grid";
    }
  | {
      type: "inlineWrap";
    };

export const MediaGalleryProps = {
  /** Media */
  media: {
    type: Object as PropType<Media[] | VaporStoreResponse[]>,
    required: true,
  },

  /** How should media previews be layed out */
  previewLayout: {
    type: Object as PropType<PreviewMediaLayout>,
    required: false,
    default: () => ({ type: "inlineWrap" }),
  },

  /** Whether to disable media controls */
  disabled: {
    required: false,
    type: Boolean,
  },

  /** Whether the content builder is in read only mode */
  isReadOnly: {
    required: false,
    type: Boolean,
  },
};

export default defineComponent({
  name: "MediaGallery",

  components: { FontAwesomeIcon },
  props: MediaGalleryProps,

  emits: {
    removeMedia: (media: Media) => MediaSchema.safeParse(media).success,
    removeVaporMedia: (file: VaporMedia) => VaporMediaSchema.safeParse(file).success,
  },

  setup() {
    const { getDefaultImageMimeTypes } = useMimeTypes();

    return {
      getDefaultImageMimeTypes,
    };
  },

  computed: {
    imageLayoutClassList() {
      return [
        this.previewLayout.type === "inlineWrap" && "h-28 w-28",
        this.previewLayout.type === "grid" && "min-h-32 min-w-32 h-full w-full",
      ];
    },

    containerGridLayoutClassList() {
      const columns = Math.min(3, this.getMediaWithPreview().length);

      return ["grid", "gap-4", ["grid-cols-1", "grid-cols-2", "grid-cols-3"][columns - 1]];
    },

    typedMedia() {
      return (this.media ?? /* istanbul ignore next -- @preserve */ []).map((rawMediaItem): MediaItem => {
        const mediaResult = MediaSchema.safeParse(rawMediaItem);

        if (mediaResult.success) {
          return {
            ...mediaResult.data,
            type: "media",
          };
        }

        return {
          ...VaporMediaSchema.parse(rawMediaItem),
          type: "vapor",
        };
      });
    },
  },

  methods: {
    getMediaItemPreviewUri(mediaItem: MediaItem) {
      if (mediaItem.type === "media") {
        const thumbUrl = mediaItem.conversions?.thumb_url;

        /* istanbul ignore if -- @preserve */
        if (thumbUrl == null) {
          throw new Error("Thumb url cannot be null");
        }

        return thumbUrl;
      }

      return this.getMediaItemUri(mediaItem);
    },

    getMediaItemBaseName(mediaItem: MediaItem) {
      if (mediaItem.type === "media") return mediaItem.name;

      const fileName = this.getMediaItemFileName(mediaItem);

      return fileName.substring(0, fileName.lastIndexOf("."));
    },

    getMediaItemFileName(mediaItem: MediaItem) {
      if (mediaItem.type === "media") return mediaItem.file_name;

      return mediaItem.file.name;
    },

    getMediaItemKey(mediaItem: MediaItem) {
      return mediaItem.type === "media" ? mediaItem.id : mediaItem.uuid;
    },

    getMediaItemUri(mediaItem: MediaItem) {
      return mediaItem.type === "media" ? mediaItem.full_url : mediaItem.url;
    },

    canPreviewVaporMediaItem(mediaItem: VaporMedia) {
      return this.getDefaultImageMimeTypes().includes(mediaItem.file.type);
    },

    getMediaWithPreview() {
      return this.typedMedia.filter((media) =>
        media.type === "media" ? media.has_preview : this.canPreviewVaporMediaItem(media)
      );
    },

    getMediaWithoutPreview() {
      return this.typedMedia.filter((media) =>
        media.type === "media" ? !media.has_preview : !this.canPreviewVaporMediaItem(media)
      );
    },

    removeMedia(media: MediaItem) {
      /* istanbul ignore if -- @preserve */
      if (this.isReadOnly || this.disabled) {
        return;
      }

      if (media.type === "media") {
        this.$emit("removeMedia", omit(media, "type"));
      } else {
        this.$emit("removeVaporMedia", omit(media, "type"));
      }
    },
  },
});
</script>

<style>
.media-gallery {
  grid-auto-rows: 1fr;
}
</style>

<template>
  <button
    type="button"
    class="relative flex items-center text-left space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus:ring-2 focus:ring-blue-500 focus:ring-offset-0 hover:border-gray-400"
    :disabled="disabled"
    @click="$emit('click', $event)"
  >
    <div class="min-w-0 flex-1">
      <span class="absolute inset-0" aria-hidden="true"></span>

      <slot>
        <p class="text-sm font-medium text-gray-900">{{ title }}</p>

        <p class="truncate text-sm text-gray-500">{{ description }}</p>
      </slot>
    </div>
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DisabledProp from "@/base/props/DisabledProp.ts";

export const Props = {
  /** Title for the card button */
  title: {
    type: String,
    required: false,
  },

  /** Description for the card button */
  description: {
    type: String,
    required: false,
  },

  /** Used to disable elements in a component */
  disabled: DisabledProp["disabled"],
};

export default defineComponent({
  name: "BaseCardButton",

  props: Props,

  emits: {
    click: (_event: Event) => true,
  },
});
</script>

<template>
  <div class="w-full">
    <div class="bg-white pt-2 pb-4 border-b">
      <div class="-ml-4 -mt-4 sm:flex sm:space-y-0 space-y-2 flex-wrap items-center justify-between sm:flex-nowrap">
        <div class="flex items-center gap-x-4 ml-4">
          <slot name="pageImage"></slot>

          <div>
            <BaseBreadcrumb
              v-if="breadcrumbs.length > 0"
              class="min-h-4"
              :breadcrumbs="breadcrumbs"
              style-type="slashesCompact"
            ></BaseBreadcrumb>

            <h3 data-testid="MainWrapperPageHeading" class="font-bold leading-6 text-gray-900 text-2xl mt-2">
              <slot name="pageHeading" :page-heading="pageHeading">
                {{ pageHeading }}
              </slot>
            </h3>

            <p
              v-if="pageSubHeading"
              class="text-base text-gray-500 mt-1 font-semibold"
              data-testid="main-wrapper-page-sub-heading"
            >
              <slot name="pageSubHeading" :page-sub-heading="pageSubHeading">
                {{ pageSubHeading }}
              </slot>
            </p>
          </div>
        </div>

        <div class="ml-4 flex-shrink-0">
          <slot name="pageButtonBar"> </slot>
        </div>
      </div>
    </div>

    <div>
      <div v-if="$slots['nav']" class="mt-3" data-testid="main-page-wrapper-nav">
        <slot name="nav"></slot>
      </div>

      <slot name="pageContent"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import BaseBreadcrumb from "@/base/components/breadcrumbs/BaseBreadcrumb.vue";
import assertStringIsNotBlank from "@/base/functions/asserts/strings/AssertStringIsNotBlank.ts";
import BreadcrumbsProp from "@/base/props/BreadcrumbProps.ts";
import type { PropType } from "vue";
import { defineComponent } from "vue";

export const MainPageLayoutProps = {
  /** Main page heading. */
  pageHeading: {
    required: true,
    type: String,
    validator: (pageHeading: string) => assertStringIsNotBlank(pageHeading),
  },

  /** Optional subheading for the page. */
  pageSubHeading: {
    required: false,
    type: [String, Boolean] as PropType<string | true | undefined>,
    default: undefined,
  },

  ...BreadcrumbsProp,
};

export default defineComponent({
  name: "MainPageLayout",

  components: { BaseBreadcrumb },
  props: MainPageLayoutProps,
});
</script>

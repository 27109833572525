<!-- eslint-disable vuejs-accessibility/label-has-for -->
<template>
  <div v-click-away="onClickAway" class="relative">
    <label
      :for="'dp-input-' + id"
      class="block mb-2"
      data-testid="text-label"
      :class="{ 'sr-only': hideLabel, [labelClass]: true }"
    >
      {{ label }}
    </label>

    <div
      v-show="inline === true && !showInlineDatePicker"
      class="border border-gray-300 rounded-md p-2 h-10 text-base relative w-full"
      role="button"
      tabindex="0"
      aria-label="Open date picker"
      @click="showInlineDatePicker = true"
      @keydown.enter="showInlineDatePicker = true"
    >
      <div class="pointer-events-none absolute inset-y-0 left-0 flex pl-2.5 py-1 items-center text-sm">
        <FontAwesomeIcon class="text-gray-400" :icon="['fal', 'calendar']" fixed-width aria-hidden="true" />
      </div>

      <div class="pl-7">
        {{ display }}
      </div>
    </div>

    <div v-show="inline === false || showInlineDatePicker" class="w-full">
      <VueDatePicker v-bind="dateElementProps" class="w-full" @update:model-value="handleEvent">
        <!-- Disable time picker when is date only -->
        <template v-if="isDate" #time-picker="{}"> </template>
      </VueDatePicker>
    </div>
    <!-- Error message -->
    <p
      v-show="hasErrorMessage"
      :id="errorId"
      :aria-hidden="!hasErrorMessage"
      class="mt-2 font-normal text-sm text-red-600"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>

<script lang="ts">
import useModelValue from "@/base/composables/ModelValueComposable.ts";
import { ClickAway } from "@/base/directives/ClickAwayDirective.ts";
import DateTimeFormatsEnum from "@/base/enums/DateTimeFormatsEnum.ts";
import assertStringIsNotBlank from "@/base/functions/asserts/strings/AssertStringIsNotBlank.ts";
import useContextDateTimeZoneComposable from "@/date/composables/ContextDateTimeZoneComposable.ts";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCalendar } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import type { PropType } from "vue";
import { defineComponent } from "vue";

library.add(faCalendar);

const props = {
  /** the date as a string in the same format as valueFormat */
  modelValue: {
    type: String as PropType<string | null | undefined>,
    required: false,
    default: undefined,
  },

  /** Whether to disable the input */
  disabled: {
    type: Boolean,
    required: false,
  },

  /** Html id for the input */
  id: {
    type: String,
    required: true as const,
  },

  /** Html name for the input */
  name: {
    type: String,
    required: true as const,
  },

  /** Html placeholder for the input */
  placeholder: {
    type: String as PropType<string | undefined>,
    required: false,
    default: undefined,
  },

  /** Flag telling whether to support date inputting */
  date: {
    type: Boolean as PropType<boolean | undefined>,
    required: false,
  },

  /** Flag telling whether to support time inputting */
  time: {
    type: Boolean as PropType<boolean | undefined>,
    required: false,
  },

  /**
   * Input label text
   */
  label: {
    required: true as const,
    type: String as PropType<string>,
    validator: (label: string) => assertStringIsNotBlank(label),
  },

  hideLabel: {
    type: Boolean as PropType<boolean>,
    required: false,
    default: false,
  },

  /**
   * Error message to render with the input
   *
   * Should only be used if there is a error to display
   */
  errorMessage: {
    required: false,
    type: String as PropType<string | undefined>,
    default: undefined,
  },

  /**
   * Id HTML attribute for error message
   */
  errorId: {
    required: false,
    type: String,
    default: "error",
    validator: (id: string) => assertStringIsNotBlank(id),
  },

  /**
   * Use inline mode.
   */
  inline: {
    type: Boolean as PropType<boolean | undefined>,
    required: false,
    default: false,
  },

  /**
   * Whether the input can be cleared.
   */
  canClear: {
    type: Boolean as PropType<boolean | undefined>,
    required: false,
    default: true,
  },

  /**
   * Class for the field label.
   */
  labelClass: {
    type: String,
    required: false,
    default: "text-sm font-medium text-gray-700",
  },
};

/**
 * Date input with label
 * @author Aaron MacDougall
 */
export default defineComponent({
  name: "BaseInputDate",

  components: {
    VueDatePicker,
    FontAwesomeIcon,
  },

  directives: {
    "click-away": ClickAway,
  },

  inheritAttrs: false,

  props,

  emits: {
    "update:modelValue": (_value: string | null) => true,
  },

  setup() {
    const { updateModelValue } = useModelValue<string>();
    const { parseInputIntoUtc, formatInContextTimeZone } = useContextDateTimeZoneComposable();

    return {
      parseInputIntoUtc,
      updateModelValue,
      formatInContextTimeZone,
    };
  },

  data() {
    return {
      showInlineDatePicker: false,
    };
  },

  computed: {
    hasErrorMessage() {
      return this.errorMessage !== undefined;
    },

    vueDatePickerDisplayFormat() {
      if (this.isDate) {
        return "d MMMM yyyy";
      }
      if (this.isTime) {
        return "HH:mm";
      }
      return "d MMMM yyyy HH:mm";
    },

    inlineDisplayFormat() {
      if (this.isDate) {
        return "D MMMM YYYY";
      }
      if (this.isTime) {
        return false;
      }
      return "D MMMM YYYY HH:mm";
    },

    modelType() {
      if (this.isDate) {
        return DateTimeFormatsEnum.VuepicDate;
      }
      if (this.isTime) {
        return DateTimeFormatsEnum.Time;
      }
      return DateTimeFormatsEnum.VuepicDateTime;
    },

    isDatetime() {
      return this.date === true && this.time === true;
    },

    isDate() {
      return this.date === true && this.time === false;
    },

    isTime() {
      return this.time === true && this.date === false;
    },

    display() {
      if (typeof this.modelValue === "string" && this.modelValue !== "") {
        if (this.inlineDisplayFormat !== false) {
          return this.formatInContextTimeZone(this.modelValue, this.inlineDisplayFormat);
        }
      }

      return this.placeholder;
    },

    dateElementProps() {
      let localModelValue = this.modelValue;

      if (localModelValue === undefined) {
        localModelValue = null;
      }

      return {
        modelValue:
          this.isDatetime && typeof localModelValue === "string" ?
            this.formatInContextTimeZone(localModelValue, "YYYY-MM-DD HH:mm:ss")
          : localModelValue,

        format: this.vueDatePickerDisplayFormat,
        uid: this.id,
        name: this.name,
        clearable: this.canClear === true,
        "model-type": this.modelType,
        "time-picker": this.isTime,
        "time-picker-inline": this.isDatetime,
        inline: this.inline === true ? { input: true } : false,
        "auto-apply": this.inline === true,
        ...this.optionalProp("disabled"),
        ...this.optionalProp("placeholder"),
        ui: {
          input:
            this.hasErrorMessage ?
              "!text-red-900 !outline-0 ring-1 !ring-red-300 focus:!ring-inset focus:!ring-red-500 focus:ring-2"
            : "",
        },
      };
    },
  },

  methods: {
    hideInlineDatePicker() {
      if (this.inline === true) {
        this.showInlineDatePicker = false;
      }
    },

    onClickAway() {
      this.hideInlineDatePicker();
    },

    handleEvent($event: string): void {
      let value = $event;

      if (this.isDatetime && typeof value === "string") {
        value = this.parseInputIntoUtc(value).toISOString();
      }

      this.hideInlineDatePicker();

      this.updateModelValue(value);
    },

    optionalValue<K extends string, T>(name: K, value: T) {
      return value !== undefined ? { [name]: value } : {};
    },

    optionalProp<T extends keyof typeof props>(propName: T) {
      return this.optionalValue(propName, this.$props[propName]);
    },
  },
});
</script>

<style scoped lang="scss">
:deep(.dp__main > div:first-child) {
  @apply w-full;
}

:deep(.dp__outer_menu_wrap) {
  @apply absolute top-full;
  z-index: 1;
}

:deep(.dp__input_wrap) {
  width: 100%;
}
</style>

<template>
  <div class="flex md:flex-row flex-col md:gap-0 gap-6">
    <div class="flex-shrink-0 md:min-w-56">
      <slot name="nav-menu"></slot>
    </div>

    <div class="w-full md:px-6 lg:px-8 space-y-8">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "SideBarMenuPageLayout",
});
</script>

<template>
  <div class="border-b border-gray-200">
    <nav class="-mb-px mt-2 flex space-x-8" :aria-label="attributeAriaLabel">
      <RouterLink v-for="tab in tabs" :key="tab.name" v-slot="{ isActive, href, navigate }" :to="tab.to" custom>
        <a
          :href="href"
          class="base-desktop-tab-menu-tab whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
          :class="[
            isActive ?
              'border-blue-500 text-blue-600'
            : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
          ]"
          @click.prevent="navigate()"
        >
          {{ tab.name }}
        </a>
      </RouterLink>
    </nav>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import getTabsProp from "../TabProps.ts";

/**
 * A tab menu that displays a list of tabs.
 *
 * - Uses router links to handle navigation.
 * - Uses a select element for mobile devices.
 * - Allows for active styling of the current tab.
 */
export default defineComponent({
  name: "BaseDesktopTabMenu",

  props: {
    /**
     * The tabs to display.
     */
    tabs: getTabsProp(true),

    /**
     * The aria label for the tab menu.
     */
    attributeAriaLabel: {
      required: true,
      type: String,
    },
  },
});
</script>

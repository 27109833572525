import type { ArgumentsType } from "vitest";

/**
 * Reusable code for handling unexpected errors.
 * @param emitFn The emit function from the component. This is required so we can get full type hinting.
 * @returns functions
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/ban-types
export function useAwaitableEmitComposable<EmitFn extends Function>(emitFn: EmitFn) {
  type EmitFnArguments = ArgumentsType<typeof emitFn>;
  type EmitFnNonPromiseArguments = EmitFnArguments extends [...infer Tuple, unknown, unknown] ? Tuple : EmitFnArguments;

  type PromiseReturnType =
    EmitFnArguments extends [...unknown[], (value: infer ResolveType) => void, unknown] ? ResolveType : unknown;

  return {
    async emitAwaitable(...args: EmitFnNonPromiseArguments) {
      return new Promise<PromiseReturnType>((resolve, reject) => {
        emitFn(...args, resolve, reject);
      });
    },
  };
}

export default useAwaitableEmitComposable;

<template>
  <div>
    <div v-if="canUpload" class="flex items-center" :class="[inputType === 'inline' && 'hidden', !hideInput && 'mb-4']">
      <div>
        <BaseInputFile
          :id="`${idPrefix}-media-input`"
          :key="clearFileInputKey"
          :class="hideInput && 'hidden'"
          label="Media"
          hide-label
          :name="`${idPrefix}-media-files`"
          :disabled="disabled"
          :accept="accept"
          :open-key="openFileInputKey"
          :error-message="errorMessage"
          :error-id="errorId"
          :drag-and-drop="dragAndDrop"
          @update:model-value="onFileInput"
        >
        </BaseInputFile>
      </div>

      <BaseLoadingSpinner v-if="loading && !hideInput" class="ml-2"></BaseLoadingSpinner>

      <slot
        name="inline-input"
        :open-file-input="/* istanbul ignore next -- @preserve */ () => (openFileInputKey += 1)"
        :disabled="disabled"
        :loading="loading"
        :media-count="(media ?? /* istanbul ignore next -- @preserve */ []).length"
      >
      </slot>
    </div>

    <MediaGallery
      :media="media ?? /* istanbul ignore next -- @preserve */ []"
      :preview-layout="previewLayout"
      :disabled="disabled"
      @remove-media="$emit('removeMedia', $event)"
      @remove-vapor-media="$emit('removeVaporMedia', $event)"
    >
      <template v-if="(inputType === 'inline' || inputType === 'both') && canUpload" #preview-item="{ classList }">
        <BaseCardButton
          class="group"
          :class="classList"
          @click="/* istanbul ignore next -- @preserve */ openFileInputKey++"
        >
          <span class="sr-only">Add Media</span>

          <div class="justify-center flex items-center">
            <BaseLoadingSpinner v-if="loading"></BaseLoadingSpinner>

            <template v-else>
              <FontAwesomeIcon
                :icon="['fal', 'circle-plus']"
                class="text-gray-400 text-2xl group-focus:hidden"
                aria-hidden="true"
                fixed-width
              />

              <FontAwesomeIcon
                :icon="['far', 'circle-plus']"
                class="text-blue-500 text-2xl group-focus:inline hidden"
                aria-hidden="true"
                fixed-width
              />
            </template>
          </div>
        </BaseCardButton>
      </template>
    </MediaGallery>
  </div>
</template>

<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";
import BaseInputFile, { BaseInputFileProps } from "@/base/components/inputs/BaseInputFile.vue";
import { RequiredStringProp } from "@/base/props/StringProp.ts";
import MediaGallery, { MediaGalleryProps } from "@/media/components/MediaGallery.vue";
import useAwaitableEmit from "@/base/composables/AwaitableEmitComposable.ts";
import { faCirclePlus as falCirclePlus } from "@fortawesome/pro-light-svg-icons";
import { faCirclePlus as farCirclePlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import BaseCardButton from "@/base/components/buttons/BaseCardButton.vue";
import type Media from "../interfaces/Media";
import type VaporMedia from "../interfaces/VaporMediaInterface";
import VaporMediaSchema from "../schemas/VaporMediaSchema.ts";
import MediaSchema from "../schemas/Media.ts";
import BaseLoadingSpinner from "@/base/components/loading/BaseLoadingSpinner.vue";
import assertStringIsNotBlank from "@/base/functions/asserts/strings/AssertStringIsNotBlank.ts";

library.add(falCirclePlus, farCirclePlus);

export const PureMediaContainerProps = {
  /**
   * The prefix for the id attribute of elements in this component.
   */
  idPrefix: RequiredStringProp,

  /** List of acceptable comma separated mime types */
  accept: BaseInputFileProps.accept,

  /** Media */
  media: MediaGalleryProps.media,

  /** Should the container be in a loading state */
  loading: {
    type: Boolean,
    required: false,
    default: false,
  },

  /**
   * Should the input be hidden.
   * Only applies in inline mode.
   */
  hideInput: {
    type: Boolean,
    required: false,
    default: false,
  },

  /** How should media previews be layed out */
  previewLayout: MediaGalleryProps.previewLayout,

  /** Whether to disable media controls */
  disabled: BaseInputFileProps.disabled,

  /**
   * Controls the different file input types.
   * Inline will place a box with a plus icon in the previews
   * while block is a separate file input.
   * Inline should only be used when the preview layout is inline wrap.
   */
  inputType: {
    type: String as PropType<"block" | "both" | "inline">,
    required: false,
    default: "block",
  },

  /** The max number of media that can be in the gallery */
  max: {
    type: [Number, undefined] as PropType<number | undefined>,
    required: false,
    default: undefined,
  },

  /**
   * Error message to render with the input
   *
   * Should only be used if there is a error to display
   */
  errorMessage: {
    required: false,
    type: String as PropType<string | undefined>,
    default: undefined,
  },

  /**
   * Id HTML attribute for error message
   */
  errorId: {
    required: false,
    type: String,
    default: "error",
    validator: (id: string) => assertStringIsNotBlank(id),
  },

  /** Whether to enable drag and drop */
  dragAndDrop: {
    required: false,
    type: Boolean,
  },
};

/**
 * Component for handling the uploading and previewing of temporary media.
 * This is to be used on model create.
 */
export default defineComponent({
  name: "PureMediaContainer",

  components: {
    BaseInputFile,
    MediaGallery,
    FontAwesomeIcon,
    BaseCardButton,
    BaseLoadingSpinner,
  },

  props: PureMediaContainerProps,

  emits: {
    addFiles: (_files: File[], _resolve: () => void, _reject: (e: string) => void) => true,
    removeMedia: (media: Media) => MediaSchema.safeParse(media).success,
    removeVaporMedia: (file: VaporMedia) => VaporMediaSchema.safeParse(file).success,
  },

  setup(_props, { emit }) {
    const { emitAwaitable } = useAwaitableEmit(emit);
    return {
      emitAwaitable,
    };
  },

  data() {
    return {
      clearFileInputKey: 0,
      openFileInputKey: 0,
    };
  },

  computed: {
    canUpload() {
      return this.max != null ? (this.media?.length ?? /* istanbul ignore next -- @preserve */ 0) < this.max : true;
    },
  },

  methods: {
    async onFileInput(files: File[]) {
      await this.emitAwaitable("addFiles", files).finally(() => {
        this.clearFileInputKey += 1;
      });
    },
  },
});
</script>

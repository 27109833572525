<template>
  <div class="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
    <dl>
      <template v-for="(item, index) in visibleItems" :key="index">
        <div
          v-if="item.type === 'title'"
          class="flex items-center flex-auto px-6 pt-6"
          :class="getItemClassList(index)"
        >
          <dt class="text-sm font-semibold leading-6 text-gray-900">{{ item.text }}</dt>
        </div>

        <div v-if="item.type === 'slot'" class="px-6 pt-6" :class="getItemClassList(index)">
          <slot :name="item.key"></slot>
        </div>

        <div v-if="item.type === 'text'" class="flex items-center flex-auto px-6 pt-6" :class="getItemClassList(index)">
          <dt class="text-sm">{{ item.text }}</dt>
        </div>

        <div v-if="item.type === 'list'" class="space-y-4 px-6 pt-6" :class="getItemClassList(index)">
          <component
            :is="'to' in subItem ? 'router-link' : 'div'"
            v-for="(subItem, subIndex) in getVisibleSubItems(item)"
            :key="subIndex"
            class="flex w-full flex-none gap-x-4"
            :class="'to' in subItem && 'hover:underline'"
            :to="getValue(subItem.to)"
          >
            <dt v-if="subItem.icon != null" class="flex-none">
              <FontAwesomeIcon :icon="subItem.icon" class="text-gray-400" aria-hidden="true" fixed-width />
            </dt>

            <dd class="text-sm font-medium leading-6" :class="subItem.textClassList">
              {{ subItem.text }}
            </dd>
          </component>
        </div>

        <div
          v-if="item.type === 'link'"
          class="mt-6 border-t border-gray-900/5 px-6 pt-6"
          :class="getItemClassList(index)"
        >
          <RouterLink :to="getValue(item.to)" class="text-sm font-semibold leading-6 text-gray-900"
            >{{ item.text }} <span aria-hidden="true">&rarr;</span></RouterLink
          >
        </div>
      </template>
    </dl>
  </div>
</template>

<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";
import type { RouteLocationRaw } from "vue-router";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

interface BaseBaseDescriptionListCardButton {
  hidden?: boolean;
}

interface BaseDescriptionListCardSlot extends BaseBaseDescriptionListCardButton {
  key: string;
  type: "slot";
}

interface BaseDescriptionListCardLink extends BaseBaseDescriptionListCardButton {
  type: "link";
  text: string;
  to: RouteLocationRaw | (() => RouteLocationRaw);
}

interface BaseDescriptionListCardTitle extends BaseBaseDescriptionListCardButton {
  type: "title";
  text: string;
}

interface BaseDescriptionListCardText extends BaseBaseDescriptionListCardButton {
  type: "text";
  text: string;
}

interface BaseDescriptionListCardList extends BaseBaseDescriptionListCardButton {
  type: "list";
  items: {
    icon?: string[] | string | undefined;
    text: string;
    textClassList?: string;
    to?: RouteLocationRaw | (() => RouteLocationRaw);
    hidden?: boolean;
  }[];
}

type BaseDescriptionListCardItem =
  | BaseDescriptionListCardLink
  | BaseDescriptionListCardList
  | BaseDescriptionListCardSlot
  | BaseDescriptionListCardText
  | BaseDescriptionListCardTitle;

function getValue<T>(val: (() => T) | T): T;
/**
 * Get value from function or value
 * @param val Value to get
 * @returns Value
 */
function getValue<T>(val: T | (() => T)): T {
  return typeof val === "function" ? (val as () => T)() : val;
}

export default defineComponent({
  name: "BaseDescriptionListCard",

  components: {
    FontAwesomeIcon,
  },

  props: {
    /**
     * Description card items
     */
    items: {
      type: Array as PropType<BaseDescriptionListCardItem[]>,
      required: true,
    },
  },

  computed: {
    visibleItems() {
      return this.items.filter((item) => item.hidden !== true);
    },
  },

  methods: {
    getValue,

    getVisibleSubItems(item: BaseDescriptionListCardList) {
      return item.items.filter((subItem) => subItem.hidden !== true);
    },

    getItemClassList(index: number) {
      return [index !== 0 && "mt-6 border-t border-gray-900/5", index === this.visibleItems.length - 1 && "pb-6"];
    },
  },
});
</script>

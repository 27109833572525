import * as Sentry from "@sentry/vue";
import { App } from "vue";
import type { Router } from "vue-router";

/**
 * Set the user in sentry.
 *
 * Must be set after a user is authenticated.
 * @param user User object in sentry format.
 * @param user.id The primary key of the user.
 * @param user.username The full name of the user.
 * @param user.email The email of the user.
 */
const setSentryUser = (user: { id: number; username: string; email: string }): void => {
  Sentry.setUser({
    id: user.id,
    username: user.username,
    email: user.email,
  });
};

const bootSentry = (app: App, router: Router): void => {
  const dsn = import.meta.env["VITE_SENTRY_DSN"];
  const environment = import.meta.env["VITE_APP_ENV"];

  const envLaravelAppUrl = import.meta.env["VITE_APP_URL"];
  const laravelAppAPIUrlRegex =
    typeof envLaravelAppUrl === "string" ? new RegExp(`^${envLaravelAppUrl}/api`) : undefined;

  Sentry.init({
    dsn,
    environment,

    // Enable integrations.
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration({
        // Additional SDK configuration goes in here, for example:
        maskAllText: false,
        blockAllMedia: true,
      }),
      Sentry.feedbackIntegration({
        // Additional SDK configuration goes in here, for example:
        colorScheme: "system",
      }),
      Sentry.vueIntegration({
        attachProps: true,
        app,
        attachErrorHandler: true,
        tracingOptions: {
          trackComponents: true,
          hooks: ["activate", "create", "update", "destroy", "mount", "unmount"],
        },
      }),
    ],

    normalizeDepth: 10,

    ...(__APP_VERSION__ !== undefined ? { release: __APP_VERSION__ } : {}),

    // Enable performance tracing.
    tracesSampleRate: 0.1,
    // Only enable tracing for API requests.
    tracePropagationTargets:
      laravelAppAPIUrlRegex !== undefined ?
        [
          laravelAppAPIUrlRegex,
          /^https:\/\/app.rezults.co.uk\/api/,
          /^https:\/\/red-london-fgbak0gaokx1.on-vapor.com\/api/,
          /^https:\/\/app.coredue.co.uk\/api/,
          /^http:\/\/localhost\/api/,
          /^http:\/\/core-app.test\/api/,
        ]
      : [],

    // Replays.
    replaysSessionSampleRate: 0, // Disable session replay.
    replaysOnErrorSampleRate: 1.0, // Always capture replay on error.

    // Offline caching.
    // Is not supported by IE browser.
    transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),

    // Enable profile tracing.
    profilesSampleRate: 0.1,
  });
};

export { setSentryUser };

export default bootSentry;

<template>
  <button class="cursor-pointer text-blue-600 hover:text-blue-900 font-medium" type="button" @click="$emit('click')">
    Show More
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";

/**
 * The base show more paginator
 *
 * - emits a click event
 */
export default defineComponent({
  name: "BaseShowMoreButton",

  emits: {
    click: () => true,
  },
});
</script>

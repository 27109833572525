<template>
  <label :for="idAttribute" class="block" data-testid="textarea-label" :class="labelClass">
    <span
      data-testid="textarea-label-text"
      v-bind="{
        ...(hideLabel ? { class: 'sr-only' } : {}),
      }"
    >
      {{ label }}
    </span>

    <textarea
      v-bind="attributes"
      :class="{ 'mt-2': !hideLabel }"
      class="disabled:bg-gray-50 disabled:cursor-not-allowed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
      @input="handleInput"
    />
  </label>

  <div class="flex justify-between w-full" :class="footerClassList">
    <!-- Error message -->
    <p
      v-show="hasErrorMessage"
      :id="errorId"
      data-testid="error-message"
      :aria-hidden="!hasErrorMessage"
      class="text-sm text-red-600 mt-2"
    >
      {{ errorMessage }}
    </p>

    <span v-if="showMaxCharacterCount" v-show="!hasErrorMessage"> &nbsp; </span>

    <BaseMaxCharacterCount
      v-if="showMaxCharacterCount && maxLength != null"
      class="mt-2"
      :model-value="modelValue"
      :max-length="maxLength"
    ></BaseMaxCharacterCount>
  </div>
</template>

<script lang="ts">
import BaseMaxCharacterCount from "@/base/components/inputs/BaseMaxCharacterCount.vue";
import useModelValue from "@/base/composables/ModelValueComposable.ts";
import assertStringIsNotBlank from "@/base/functions/asserts/strings/AssertStringIsNotBlank.ts";
import DisabledProp from "@/base/props/DisabledProp.ts";
import type { DebouncedFunc } from "lodash";
import { debounce } from "lodash";
import type { PropType } from "vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseInputTextarea",

  components: {
    BaseMaxCharacterCount,
  },

  inheritAttrs: false,
  props: {
    /**
     * Whether to hide the label
     */
    hideLabel: {
      required: false,
      type: Boolean,
    },

    /**
     * Model value prop for v-model two-way binding
     */
    modelValue: {
      required: false,
      type: String as PropType<string | null | undefined>,
      default: undefined,
    },

    /**
     * HTML attribute to prevent browser interaction
     */
    disabled: DisabledProp.disabled,

    /**
     * The label text or screen reader accessible text if hidden.
     */
    label: {
      required: true,
      type: String,
    },

    /**
     * Id HTML attribute
     */
    idAttribute: {
      required: true,
      type: String,
    },

    /** Debounce time in milliseconds */
    debounce: {
      required: false,
      type: Number as PropType<number | undefined>,
      default: 0,
      validator: (val: number) => val >= 0,
    },

    /**
     * Error message to render with the input
     *
     * Should only be used if there is a error to display
     */
    errorMessage: {
      required: false,
      type: String as PropType<string | undefined>,
      default: undefined,
    },

    /**
     * Id HTML attribute for error message
     */
    errorId: {
      required: false,
      type: String,
      default: "error",
      validator: (id) => assertStringIsNotBlank(id),
    },

    /**
     * Class for the field label.
     */
    labelClass: {
      type: String,
      required: false,
      default: "text-sm font-medium text-gray-700",
    },

    /**
     * Class for the field footer (error container and max character count).
     */
    footerClassList: {
      type: String,
      required: false,
      default: "",
    },

    /**
     * The maximum length of the input string.
     */
    maxLength: {
      type: [Number, null, undefined] as PropType<number | null | undefined>,
      required: false,
      default: undefined,
    },

    /**
     * The number of rows that are visible.
     */
    rows: {
      type: [Number, null, undefined] as PropType<number | null | undefined>,
      required: false,
      default: undefined,
    },

    /**
     * Should the current character count and max character count be hidden.
     */
    hideMaxCharacterCount: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },

  emits: {
    "update:modelValue": (_value: string) => true,
  },

  setup() {
    const { updateModelValue } = useModelValue();

    return {
      updateModelValue,
    };
  },

  data() {
    return {
      debounceFunction: undefined as DebouncedFunc<(event: Event) => void> | undefined,
    };
  },

  computed: {
    showMaxCharacterCount() {
      return this.maxLength != null && !this.hideMaxCharacterCount;
    },

    usesDebounce() {
      return this.debounce !== undefined && this.debounce > 0;
    },

    errorInputClass() {
      return this.hasErrorMessage ?
          "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-inset focus:ring-red-500"
        : "text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-600";
    },

    hasErrorMessage() {
      return this.errorMessage !== undefined;
    },

    attributes() {
      return {
        rows: this.rows ?? 4,
        ...this.$attrs,
        id: this.idAttribute,
        ...(this.modelValue !== undefined ? { value: this.modelValue } : {}),
        class: ["class" in this.$attrs ? this.$attrs["class"] : "", this.errorInputClass] as string[],
        "aria-invalid": this.hasErrorMessage,
        "aria-describedby": this.errorId,
        ...(this.disabled ? { disabled: this.disabled } : {}),
      };
    },
  },

  created() {
    if (!this.usesDebounce) {
      return;
    }

    this.debounceFunction = debounce(($event: Event) => {
      this.callUpdateModelValueAfterInputEvent($event);
    }, this.debounce);
  },

  methods: {
    flush() {
      /* istanbul ignore else -- @preserve */
      if (this.debounceFunction) {
        this.debounceFunction.flush();
      }
    },

    handleInput($event: Event) {
      if (this.disabled) {
        return;
      }

      if (this.usesDebounce && this.debounceFunction) {
        this.debounceFunction($event);

        return;
      }

      this.callUpdateModelValueAfterInputEvent($event);
    },

    callUpdateModelValueAfterInputEvent($event: Event) {
      const input = $event.target as HTMLInputElement | null;

      /* istanbul ignore next -- @preserve */
      if (!input || !("value" in input)) {
        return;
      }

      const { maxLength } = this;

      if (maxLength != null) {
        if (input.value.length > maxLength) {
          input.value = input.value.substring(0, maxLength);
        }
      }

      this.updateModelValue(input.value);
    },
  },
});
</script>

<template>
  <div
    aria-live="assertive"
    role="alert"
    class="pointer-events-none fixed sm:inset-auto inset-0 flex items-end px-4 py-6 sm:items-start sm:p-0"
  >
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <transition v-bind="transitionProps">
        <div
          v-show="show"
          :aria-hidden="!show"
          class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
        >
          <div class="p-4">
            <div class="flex items-start">
              <!-- Notification Content -->
              <div class="flex-shrink-0">
                <span v-if="type === 'success'" data-testid="check-circle-icon">
                  <CheckCircleIcon class="h-6 w-6 text-green-400" />
                </span>

                <span v-if="type === 'error'" data-testid="x-circle-icon">
                  <XCircleIcon class="h-6 w-6 text-red-400" />
                </span>
              </div>

              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm font-medium text-gray-900">{{ title }}</p>

                <p v-if="message" class="mt-1 text-sm text-gray-500">{{ message }}</p>
              </div>

              <!-- Close button -->
              <div class="ml-4 flex flex-shrink-0">
                <button
                  type="button"
                  class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  @click="close()"
                >
                  <span class="sr-only">Close</span>

                  <XMarkIcon class="h-5 w-5" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
import { CheckCircleIcon, XMarkIcon, XCircleIcon } from "@heroicons/vue/24/outline";
import type { PropType } from "vue";
import { defineComponent } from "vue";

/**
 * A notification component that displays a message to the user.
 *
 * - The notification is automatically hidden after the specified duration.
 * - The notification can be closed by the user.
 * - The notification can be of type `success` or `error`.
 */
export default defineComponent({
  name: "BaseNotification",

  components: { XMarkIcon, CheckCircleIcon, XCircleIcon },
  props: {
    /** The message to display in the notification. */
    title: {
      type: String,
      required: true,
    },

    /** The message to display in the notification. */
    message: {
      type: [String] as PropType<string | undefined>,
      required: false,
      default: undefined,
    },

    /** The duration to display the notification for, in milliseconds. */
    duration: {
      type: Number,
      default: 5000,
    },

    /** Whether or not the notification is visible. */
    show: {
      required: true,
      type: Boolean,
    },

    /** The type of notification to display. */
    type: {
      type: String as PropType<"error" | "success">,
      default: "success",
      validator: (value: string) => {
        return ["success", "error"].includes(value);
      },
    },
  },

  emits: {
    close: () => true,
  },

  data() {
    return {
      timeout: undefined as ReturnType<typeof setTimeout> | undefined,
    };
  },

  computed: {
    /**
     * The transition properties to use for the notification.
     * @returns the props
     */
    transitionProps(): Record<string, string> {
      return {
        // The transition to use when the notification is displayed.
        enterActiveClass: "transform ease-out duration-300 transition",
        enterFromClass: "-translate-y-2 opacity-0 sm:-translate-y-4",
        enterToClass: "translate-y-0 opacity-100 sm:translate-y-0",
        // The transition to use when the notification is hidden.
        leaveActiveClass: "transition ease-in duration-100",
        leaveFromClass: "opacity-100",
        leaveToClass: "opacity-0",
      };
    },
  },

  watch: {
    show: {
      immediate: true,
      handler(val) {
        if (val === true && this.duration > 0) {
          // Close the notification after the specified duration.
          this.timeout = setTimeout(() => {
            this.close();
          }, this.duration);
        } else if (this.timeout) {
          // Clear the timeout if the notification is closed before the duration has elapsed.
          clearTimeout(this.timeout);
        }
      },
    },
  },

  methods: {
    close() {
      this.$emit("close");
    },
  },
});
</script>

import AnalyticEventsEnum from "@/analytics/enums/AnalyticEventsEnum.ts";
import analyticTrack from "@/analytics/functions/AnalyticEvent.ts";
import errorHandler from "@/app/error/ErrorHandler.ts";
import type User from "@/auth/interfaces/User.ts";
import type UserCurrentCompanySitesPermissionsInterface from "@/permissions/interfaces/UserSitePermissions.ts";
import type Permissions from "@/permissions/types/Permissions.ts";
import type { CompanyTag } from "@/tag/interfaces/Tag.ts";
import { CompanyTagSchema } from "@/tag/schemas/TagSchema.ts";
import * as Sentry from "@sentry/vue";
import dayjs from "dayjs";
import { pick } from "lodash";
import { defineStore } from "pinia";
import { z } from "zod";
import AuthenticatedDispatcher from "../classes/AuthenticatedDispatcher.ts";

interface AuthenticationStoreState {
  authenticatedUser: User | undefined;
  hasAttemptedToAuthenticate: boolean;
  versionMismatchDetected: boolean;
  isOnLine: boolean;
}

/**
 * Authentication store for handling user authentication status
 */
const useAuthenticationStore = defineStore("authentication", {
  state: (): AuthenticationStoreState => {
    return {
      authenticatedUser: undefined,
      hasAttemptedToAuthenticate: false,
      versionMismatchDetected: false,
      isOnLine: navigator.onLine,
    };
  },
  getters: {
    /**
     * Checks if there is a authenticated user in the store
     * @returns is the user authenticated
     */
    isAuthenticated(): boolean {
      return this.authenticatedUser?.id !== undefined;
    },

    /**
     * Checks if the user is a super admin.
     *
     * Super admins have access to all companies and sites.
     * @returns is the user a super admin
     */
    isSuperAdmin(): boolean {
      return this.authenticatedUser?.is_super_admin !== undefined ? this.authenticatedUser.is_super_admin : false;
    },

    /**
     * Gets the current company permissions for the authenticated user.
     *
     * These permissions should only be used to check permissions relating to the current company.
     *
     * If the user is not authenticated or does not have a current company, this will return `null`.
     * @returns the current company permissions,
     * returns `null` if the user is not authenticated or does not have a current company.
     */
    currentCompanyPermissions(): Permissions | undefined {
      return Array.isArray(this.authenticatedUser?.current_company_permissions) ?
          this.authenticatedUser.current_company_permissions
        : undefined;
    },

    sitePermissions(): UserCurrentCompanySitesPermissionsInterface | undefined {
      return Array.isArray(this.authenticatedUser?.current_company_sites_permissions) ?
          this.authenticatedUser.current_company_sites_permissions
        : undefined;
    },

    /**
     * Gets the current company ID for the authenticated user.
     *
     * If the user is not authenticated or does not have a current company, this will return `undefined`.
     * @returns the current company ID.
     */
    currentCompanyId(): number | undefined {
      return typeof this.authenticatedUser?.current_company_id === "number" ?
          this.authenticatedUser.current_company_id
        : undefined;
    },

    currentCompanyAvatarFullUrl(): string | undefined {
      return typeof this.authenticatedUser?.current_company_avatar_full_url === "string" ?
          this.authenticatedUser.current_company_avatar_full_url
        : undefined;
    },

    currentCompanyRole(): string | undefined {
      return typeof this.authenticatedUser?.current_company_role === "string" ?
          this.authenticatedUser.current_company_role
        : undefined;
    },

    currentSiteWorkDepartments(): CompanyTag[] | undefined {
      return z.array(CompanyTagSchema).safeParse(this.authenticatedUser?.current_site_work_departments).data;
    },

    /**
     * Gets the current company name for the authenticated user.
     *
     * If the user is not authenticated or does not have a current company, this will return `undefined`.
     * @returns the current company name.
     */
    currentCompanyName(): string | undefined {
      return typeof this.authenticatedUser?.current_company_name === "string" ?
          this.authenticatedUser.current_company_name
        : undefined;
    },
  },
  actions: {
    afterAuthentication(authUser: User) {
      new AuthenticatedDispatcher(authUser).dispatch();
    },
    /**
     * Calls the `api/user` endpoint to invalidate the session
     * and unsets the authenticated user.
     */
    async logoutAuthenticatedUser() {
      await window.httpClient
        .post("/logout")
        .then(() => {
          window.location.href = "/";
        })
        .catch(() => {
          errorHandler(new Error("Failed to logout"));
        });
    },

    updateAuthenticatedUser(
      update: Partial<Pick<User, "avatar" | "current_company_avatar_full_url" | "email" | "first_name" | "last_name">>
    ) {
      this.authenticatedUser = {
        ...this.findAuthenticatedUserOrFail(),
        ...pick(update, ["first_name", "last_name", "email", "avatar", "current_company_avatar_full_url"]),
      };
    },

    /**
     * Calls the `api/user` endpoint to get the authenticated user.
     * @returns the authenticated user
     */
    async setAuthenticatedUser(): Promise<User | undefined> {
      let res;

      try {
        res = await window.httpClient.get("/api/user", { withCredentials: true });
      } catch (error) {
        this.authenticatedUser = undefined;

        return undefined;
      } finally {
        this.hasAttemptedToAuthenticate = true;
      }

      this.authenticatedUser = res.data.data;

      /* istanbul ignore if -- @preserve */
      if (this.authenticatedUser === undefined) {
        return undefined;
      }

      this.afterAuthentication(this.authenticatedUser);

      return this.authenticatedUser;
    },
    /**
     * Finds the current company ID for the authenticated user
     * @throws {Error} if the user is not authenticated
     * @throws {Error} if the user does not have a current company
     * @throws {Error} if the user's current company ID is invalid
     * @returns the current company ID
     */
    findCurrentCompanyIdOrFail(): number {
      if (!this.isAuthenticated) {
        throw new Error("User is not authenticated");
      }

      const companyId = this.currentCompanyId;

      if (typeof companyId !== "number") {
        throw new Error("User does not have a current company");
      }

      if (companyId < 1) {
        throw new Error("Invalid company ID");
      }

      return companyId;
    },

    findCurrentSiteId(): number | null | undefined {
      return this.authenticatedUser?.current_site_id;
    },

    findCurrentCompanyNameOrFail(): string {
      if (!this.isAuthenticated) {
        throw new Error("User is not authenticated");
      }

      const companyName = this.currentCompanyName;

      if (typeof companyName !== "string") {
        throw new Error("User does not have a current company");
      }

      return companyName;
    },

    findAuthenticatedUserOrFail(): User {
      if (this.authenticatedUser === undefined) {
        throw new Error("User is not authenticated");
      }

      return this.authenticatedUser;
    },

    shouldPerformVersionMismatchRefresh() {
      if (!this.versionMismatchDetected) {
        return false;
      }

      if (__APP_VERSION__ === undefined) {
        Sentry.captureMessage("The frontend client version is not defined", {
          level: "fatal",
        });

        return false;
      }

      const lastRefreshed = localStorage.getItem("lastRefreshedAt");

      if (lastRefreshed !== null) {
        const typedLastRefreshed = parseInt(lastRefreshed, 10);

        if (!z.coerce.number().safeParse(typedLastRefreshed).success) {
          Sentry.captureMessage(`The last refreshed at value is not a number. Value is: ${lastRefreshed}`, {
            level: "fatal",
          });

          return false;
        }

        const parsedLastRefreshed = dayjs.unix(typedLastRefreshed);

        if (!parsedLastRefreshed.isValid()) {
          Sentry.captureMessage(`The last refreshed at value is not a valid date. Value is: ${lastRefreshed}`, {
            level: "fatal",
          });

          return false;
        }

        const tenMinutesAgo = dayjs().subtract(10, "minutes");

        if (parsedLastRefreshed.isAfter(tenMinutesAgo)) {
          return false;
        }
      }

      localStorage.setItem("lastRefreshedAt", dayjs().unix().toString());

      analyticTrack(AnalyticEventsEnum.appVersionMismatchRefresh, {
        currentFrontendVersion: __APP_VERSION__,
      });

      return true;
    },
  },
});

window.addEventListener("online", () => {
  const store = useAuthenticationStore();

  store.isOnLine = navigator.onLine;
});

window.ononline = () => {
  const store = useAuthenticationStore();

  store.isOnLine = navigator.onLine;
};

window.addEventListener("offline", () => {
  const store = useAuthenticationStore();

  store.isOnLine = navigator.onLine;
});

export default useAuthenticationStore;

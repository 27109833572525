<template>
  <!-- #region stacked layout -->
  <div
    v-if="layoutOptions.type === 'stacked'"
    class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6"
    :class="{
      'mt-5': !layoutOptions.noSections,
      'lg:mt-0': !layoutOptions.noSections && layoutOptions.inlineSections === true,
      'col-span-2 col-start-2': layoutOptions.type === 'stacked' && layoutOptions.inlineSections === true,
    }"
    data-testid="section-fields-container"
  >
    <slot />
  </div>

  <!-- #region inline layout -->
  <div
    v-else-if="layoutOptions.type === 'inline'"
    class="border-gray-900/10 pb-12 sm:divide-y sm:divide-gray-900/10 sm:pb-0"
    data-testid="section-fields-container"
    :class="{
      'sm:border-t': !section.hideSection || layoutOptions.noSections === true,
      'border-b': !hideBottomBorderForInlineLayout,
    }"
  >
    <slot />
  </div>

  <!-- #region horizontal layout -->
  <div v-else data-testid="section-fields-container" class="flex flex-wrap items-center">
    <slot />
  </div>
</template>

<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";
import type { LayoutOptions } from "./BaseFormInputLayout.vue";
import type { FormSection } from "./BaseFormInterface";

export default defineComponent({
  name: "BaseFormSectionLayout",

  props: {
    /** The layout options for the form */
    layoutOptions: {
      type: Object as PropType<LayoutOptions>,
      required: true,
    },

    /** The form section to render */
    section: {
      type: Object as PropType<FormSection>,
      required: true,
    },

    /** Whether to show the bottom border for inline layout */
    hideBottomBorderForInlineLayout: {
      type: Boolean,
      required: true,
    },
  },
});
</script>

<template>
  <a :href="href" :target="target" class="font-medium text-blue-600 hover:text-blue-500">
    {{ hyperlinkText }}
  </a>
</template>

<script lang="ts">
import useSanitizeUrl from "@/base/composables/strings/SanitizeUrlComposable.ts";
import assertStringIsNotBlank from "@/base/functions/asserts/strings/AssertStringIsNotBlank.ts";
/**
 * Base application hyperlink with default styling
 * @author Jamie Wood
 */
export default {
  name: "BaseHyperlink",

  props: {
    /**
     * HTML href attribute for link
     */
    href: {
      required: true,
      type: String,
      validator: (href) => assertStringIsNotBlank(href),
    },

    /**
     * Hyperlink component text
     */
    hyperlinkText: {
      required: true,
      type: String,
      validator: (text) => assertStringIsNotBlank(text),
    },

    /**
     * HTML target attribute
     */
    target: {
      required: false,
      type: String,
      validator: (target) => typeof target === "string" && ["_blank", "_self"].includes(target),
      default: "_self",
    },
  },

  setup() {
    const { sanitize } = useSanitizeUrl();

    return {
      sanitize,
    };
  },
};
</script>

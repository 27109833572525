<template>
  <RouterLink v-slot="{ navigate }" :to="to" custom>
    <a
      v-bind="buttonAttributes"
      role="link"
      tabindex="0"
      @keypress.enter.prevent="handleNavigate(navigate)"
      @click.prevent="handleNavigate(navigate)"
    >
      {{ buttonText }}
    </a>
  </RouterLink>
</template>

<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";
import type { NavigationFailure, RouteLocationRaw } from "vue-router";
import { sizeProp, themeProp, useBaseButton } from "@/base/composables/buttons/BaseButtonComposable.ts";
import assertStringIsNotBlank from "@/base/functions/asserts/strings/AssertStringIsNotBlank.ts";

/**
 * @todo Refactor event handling as it can be abstracted out
 * Can't do at the moment as importing `vue-router` in any vue component
 * Results in `$router` not being found on the component for some reason
 * Don't know enough about typescript to fix it, so `stackoverflow` will be needed
 * @todo Add the `RouteRecordRaw` prop type for the `to` prop
 */

/**
 * Vue router link with button styling
 */
export default defineComponent({
  name: "BaseButtonRouterLink",

  inheritAttrs: false,
  props: {
    /**
     * HTML disabled attribute
     */
    disabled: {
      required: false,
      type: Boolean,
    },

    /**
     * HTML name attribute
     */
    name: {
      required: false,
      type: String as PropType<string | undefined>,
      default: undefined,
      validator: (name) => assertStringIsNotBlank(name),
    },

    /**
     * The button text
     */
    buttonText: {
      required: true,
      type: String,
    },

    /**
     * The `RouteRecordRaw` prop used in the `RouterLink` component for navigation
     */
    to: {
      required: true,
      type: [Object, String] as PropType<RouteLocationRaw>,
    },

    /**
     * The appearance of the component excluding sizing
     */
    theme: themeProp,

    /**
     * The size of the button
     */
    size: sizeProp,
  },

  emits: {
    navigate: () => true,
  },

  setup(props) {
    const { buttonClassList: baseButtonClassList } = useBaseButton(props.theme, props.size);

    return {
      baseButtonClassList,
    };
  },

  computed: {
    buttonAttributes() {
      return {
        ...this.$attrs,
        ...(!this.disabled ? { href: this.$router.resolve(this.to).href } : {}),
        ...(this.name !== undefined ? { name: this.name } : {}),
        class: this.buttonClass,
        "aria-disabled": this.disabled,
      };
    },

    /**
     * Computed variable which contains classes for button styling
     * @returns returns array of classes for button
     */
    buttonClass() {
      const inheritClass = "class" in this.$attrs ? this.$attrs["class"] : "";

      return [...this.baseButtonClassList, "block", "w-auto", "text-center", inheritClass];
    },
  },

  methods: {
    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
    async handleNavigate(navigate: { (e?: MouseEvent | undefined): Promise<NavigationFailure | void> }): Promise<void> {
      await navigate().then(() => {
        this.$emit("navigate");
      });
    },
  },
});
</script>

<template>
  <!-- @todo these inputs needs to be refactored same layout, same classes -->
  <div
    :class="{
      'group border-2 border-dashed border-blue-500 hover:border-blue-300 cursor-pointer py-8 px-20 w-full rounded-md':
        dragAndDrop,
      'border-green-500': isDraggingOver,
    }"
    role="button"
    tabindex="0"
    @keydown.enter="openFileDialogue"
    @drop="dropHandler"
    @dragenter.prevent
    @dragover.prevent="isDraggingOver = true"
    @dragleave.prevent="isDraggingOver = false"
  >
    <label :for="safeId" class="text-sm font-medium text-gray-700 cursor-pointer" data-testid="text-label">
      <span
        class="mb-1"
        data-testid="text-label-text"
        v-bind="{
          ...(hideLabel ? { class: 'sr-only' } : {}),
        }"
      >
        {{ label }}
      </span>

      <input v-bind="attributes" :id="safeId" ref="input" @change="handleChange($event)" />

      <div
        class="cursor-pointer bg-black text-sm font-medium text-white px-4 py-2 w-28 group-hover:bg-gray-500"
        :class="[errorInputClass, 'class' in $attrs ? $attrs['class'] : '']"
        data-testid="file-button"
        :aria-invalid="hasErrorMessage"
        :aria-describedby="errorId"
      >
        Choose File
      </div>

      <p v-if="dragAndDrop" class="text-sm text-gray-500 mt-2" :class="{ 'group-hover:text-gray-400': dragAndDrop }">
        or drag and drop
      </p>
    </label>
  </div>

  <!-- Error message -->
  <p
    v-show="hasErrorMessage"
    :id="errorId"
    :aria-hidden="!hasErrorMessage"
    class="mt-2 font-normal text-sm text-red-600"
  >
    {{ errorMessage }}
  </p>
</template>

<script lang="ts">
import useModelValue from "@/base/composables/ModelValueComposable.ts";
import assertStringIsNotBlank from "@/base/functions/asserts/strings/AssertStringIsNotBlank.ts";
import NumberPropValidator from "@/validation/props/NumberPropValidator.ts";
import type { PropType } from "vue";
import { defineComponent } from "vue";
import z from "zod";

export const BaseInputFileProps = {
  /**
   * Id HTML attribute
   */
  id: {
    required: true,
    type: String,
    validator: (id: string) => assertStringIsNotBlank(id),
  },

  /**
   * Name HTML attribute
   */
  name: {
    required: true,
    type: String,
    validator: (name: string) => assertStringIsNotBlank(name),
  },

  /**
   * Required HTML attribute for specifying if input value is required
   */
  required: {
    required: false,
    type: Boolean,
  },

  /**
   * Input label text
   */
  label: {
    required: true,
    type: String as PropType<string>,
    validator: (label: string) => assertStringIsNotBlank(label),
  },

  /**
   * Whether to hide the label
   */
  hideLabel: {
    required: false,
    type: Boolean,
  },

  /**
   * HTML attribute to prevent browser interaction
   */
  disabled: {
    required: false,
    type: Boolean,
  },

  /**
   * Error message to render with the input
   *
   * Should only be used if there is a error to display
   */
  errorMessage: {
    required: false,
    type: String as PropType<string | undefined>,
    default: undefined,
  },

  /**
   * Id HTML attribute for error message
   */
  errorId: {
    required: false,
    type: String,
    default: "error",
    validator: (id: string) => assertStringIsNotBlank(id),
  },

  /** List of acceptable comma separated mime types */
  accept: {
    type: [Array, String, undefined] as PropType<string[] | string | undefined>,
    required: false,
    default: undefined,
    validator(value: unknown) {
      return z.array(z.string()).or(z.string()).safeParse(value).success;
    },
  },

  /** A key that when incremented, opens the file dialog */
  openKey: {
    required: false as const,
    type: Number as PropType<number>,
    validator: NumberPropValidator,
    default: 0,
  },

  /** Whether to enable drag and drop */
  dragAndDrop: {
    required: false,
    type: Boolean,
  },
};

/**
 * File input with label
 * Please note this input can only emit
 * updates as file objects only exist within
 * the browser session.
 * @author Aaron MacDougall
 * @todo add support for multiple file types
 */
export default defineComponent({
  name: "BaseInputFile",

  inheritAttrs: false,

  // Prevents inherited attributes so we can control binding

  props: BaseInputFileProps,

  emits: {
    "update:modelValue": (files: File[]) => z.array(z.instanceof(File)).safeParse(files).success,
  },

  setup() {
    const { updateModelValue } = useModelValue();
    return {
      updateModelValue,
    };
  },

  data() {
    return {
      /**
       * Whether the input is dragging over
       */
      isDraggingOver: false,
    };
  },

  computed: {
    safeId() {
      const { id } = this;

      /* istanbul ignore if -- @preserve */
      if (id == null) throw new Error("Id cannot be null");

      return id;
    },

    safeName() {
      const { name } = this;

      /* istanbul ignore if -- @preserve */
      if (name == null) throw new Error("Name cannot be null");

      return name;
    },

    attributes() {
      const attrs = { ...this.$attrs, class: "hidden" };

      return {
        ...attrs,
        id: this.safeId,
        type: "file",
        ...(this.disabled ? { disabled: this.disabled } : {}),
        ...(this.accept !== undefined ?
          { accept: Array.isArray(this.accept) ? this.accept.join(",") : this.accept }
        : {}),

        name: this.safeName,
        required: this.required,
      };
    },

    hasErrorMessage() {
      return this.errorMessage !== undefined;
    },

    errorInputClass() {
      return this.hasErrorMessage ? "ring-red-300 placeholder:text-red-300 focus:ring-inset focus:ring-red-500" : "";
    },
  },

  watch: {
    /* istanbul ignore next -- @preserve */
    openKey() {
      const { input } = this.$refs;

      if (input == null) return;

      if (input instanceof HTMLInputElement) {
        input.click();
      }
    },
  },

  methods: {
    /**
     * Open the file input file selection dialogue
     */
    openFileDialogue() {
      const { input } = this.$refs;

      /* istanbul ignore next -- @preserve */
      if (input == null || !(input instanceof HTMLInputElement)) {
        throw new Error("Input element is null or not an instance of HTMLInputElement");
      }

      input.click();
    },

    /**
     * Handle drop file event by filtering and emitting valid files
     * @param $event DragEvent the drop event
     */
    dropHandler($event: DragEvent) {
      $event.preventDefault();

      if (this.dragAndDrop === false || this.disabled || $event.dataTransfer == null) {
        return;
      }

      const localFiles: File[] = [];

      // Ensure all files are valid.
      [...$event.dataTransfer.items].forEach((item) => {
        if (item.kind === "file") {
          const file = item.getAsFile();

          // Ensure file is correct mime type.
          if (file !== null && (this.accept === undefined || this.accept.includes(file.type))) {
            localFiles.push(file);
          }
        }
      });

      // Prevent emitting if no files are valid.
      // This due to the fact we are probably getting the first index from the array after emitting.
      if (localFiles.length === 0) {
        return;
      }

      this.updateModelValue(localFiles);
    },

    handleChange($event: Event) {
      if (this.disabled) {
        return;
      }

      this.callUpdateModelValueAfterChangeEvent($event);
    },

    callUpdateModelValueAfterChangeEvent($event: Event) {
      const input = $event.target as HTMLInputElement | null;

      /* istanbul ignore next -- @preserve */
      if (!input || !("files" in input)) {
        return;
      }

      this.updateModelValue(Array.from(input.files ?? /* istanbul ignore next -- @preserve */ []));
    },
  },
});
</script>

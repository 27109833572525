<template>
  <img
    loading="lazy"
    :aria-label="media.name"
    :alt="media.name"
    :src="url ?? media.full_url"
    class="object-cover object-center flex-none bg-gray-100"
  />
</template>

<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";
import type Media from "../interfaces/Media.ts";
import type { MediaConversions } from "../interfaces/Media.ts";

export type CustomConversionKeys = Exclude<keyof MediaConversions, "thumb_url">;

export default defineComponent({
  name: "MediaImage",

  props: {
    /** Media object */
    media: {
      type: Object as PropType<Media>,
      required: true,
    },

    /** List of conversions to use before attempting to use the default */
    customConversions: {
      type: Array as PropType<CustomConversionKeys[]>,
      required: false,
      default: /* istanbul ignore next -- @preserve */ () => [],
    },
  },

  computed: {
    url() {
      let url: string | undefined;

      if (this.media.conversions == null) return url;

      // eslint-disable-next-line no-restricted-syntax
      for (const conversion of [...this.customConversions, "thumb_url"]) {
        url = this.media.conversions[conversion];

        if (url != null) break;
      }

      return url;
    },
  },
});
</script>

<template>
  <div class="bg-white rounded-[4px] w-full p-2 pb-0">
    <div class="flex flex-wrap items-start justify-between gap-8">
      <div class="div flex gap-7 flex-wrap mb-4">
        <div>
          <slot name="picture" class-list="h-40 w-40 rounded-full">
            <img :alt="pictureAltText" class="rounded-full h-40 w-40" :src="pictureUrl" />
          </slot>
        </div>

        <div class="flex flex-col gap-4">
          <div class="flex flex-wrap">
            <BaseBreadcrumb :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
          </div>

          <div class="flex flex-wrap gap-x-4">
            <h1 class="text-xl font-semibold leading-5 text-gray-800">{{ title }}</h1>
          </div>

          <slot name="details" />

          <div class="flex flex-wrap gap-6">
            <div v-if="detailBoxOneTitle || detailBoxOneContent" class="p-4 border border-gray-300 rounded-[4px]">
              <p class="text-sm leading-none text-gray-600">{{ detailBoxOneTitle }}</p>

              <p class="mt-2 text-base font-semibold leading-none text-gray-800">{{ detailBoxOneContent }}</p>
            </div>

            <div v-if="detailBoxTwoTitle || detailBoxTwoContent" class="p-4 border border-gray-300 rounded-[4px]">
              <p class="text-sm leading-none text-gray-600">{{ detailBoxTwoTitle }}</p>

              <p class="mt-2 text-base font-semibold leading-none text-gray-800">{{ detailBoxTwoContent }}</p>
            </div>

            <div v-if="detailBoxThreeTitle || detailBoxThreeContent" class="p-4 border border-gray-300 rounded-[4px]">
              <p class="text-sm leading-none text-gray-600">{{ detailBoxThreeTitle }}</p>

              <p class="mt-2 text-base font-semibold leading-none text-gray-800">{{ detailBoxThreeContent }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="flex items-center gap-3 relative">
        <button
          v-if="mainActionButtonText"
          type="button"
          class="bg-blue-700 text-white text-xs font-medium py-2 px-4 hover:bg-blue-600 rounded-[4px]"
          @click="$emit('mainAction')"
        >
          {{ mainActionButtonText }}
        </button>

        <button v-if="secondaryActionMenuShow" type="button" class="px-2 py-1 rounded bg-blue-100 hover:bg-blue-200">
          <span class="sr-only">Secondary Action Menu</span>

          <FontAwesomeIcon :icon="['fas', 'ellipsis']" />
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEllipsis } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { defineComponent } from "vue";
import type { PropType } from "vue";
import BaseBreadcrumb from "@/base/components/breadcrumbs/BaseBreadcrumb.vue";
import type Breadcrumb from "@/base/interfaces/BreadcrumbInterface";

library.add(faEllipsis);

/**
 * Base header component used for page sections
 */
export default defineComponent({
  name: "BaseOverviewHeader",

  components: {
    BaseBreadcrumb,
    FontAwesomeIcon,
  },

  props: {
    /** The breadcrumbs to pass to the breadcrumb component */
    breadcrumbs: {
      required: true,
      type: Array<Breadcrumb>,
    },

    /** The title of the header */
    title: {
      required: true,
      type: String,
    },

    /** The url of the picture */
    pictureUrl: {
      required: true,
      type: String,
    },

    /** The alt text of the picture */
    pictureAltText: {
      required: false,
      type: String,
      default: "Overview Header Picture",
    },

    /** The main (blue) action button text */
    mainActionButtonText: {
      required: false,
      type: String as PropType<string | undefined>,
      default: undefined,
    },

    /** The secondary (light blue) action button show toggle */
    secondaryActionMenuShow: {
      required: false,
      type: Boolean as PropType<boolean | undefined>,
      default: false,
    },

    /** The details box 1 title */
    detailBoxOneTitle: {
      required: false,
      type: String as PropType<string | undefined>,
      default: undefined,
    },

    /** The details box 1 content */
    detailBoxOneContent: {
      required: false,
      type: String as PropType<string | undefined>,
      default: undefined,
    },

    /** The details box 2 title */
    detailBoxTwoTitle: {
      required: false,
      type: String as PropType<string | undefined>,
      default: undefined,
    },

    /** The details box 2 content */
    detailBoxTwoContent: {
      required: false,
      type: String as PropType<string | undefined>,
      default: undefined,
    },

    /** The details box 3 title */
    detailBoxThreeTitle: {
      required: false,
      type: String as PropType<string | undefined>,
      default: undefined,
    },

    /** The details box 3 content */
    detailBoxThreeContent: {
      required: false,
      type: String as PropType<string | undefined>,
      default: undefined,
    },
  },

  emits: {
    mainAction: () => true,
  },
});
</script>

<template>
  <!-- eslint-disable-next-line vue/v-on-handler-style -->
  <BaseDropdownMenu :no-wrap="noWrap" :items="dropdownItems" @click="handleOptionUpdate"> </BaseDropdownMenu>
</template>

<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";
import BaseDropdownMenu from "@/base/components/menus/dropdownMenus/BaseDropdownMenu.vue";
import useModelValue from "@/base/composables/ModelValueComposable.ts";
import type { BaseDropdownMenuButtonItem } from "@/base/interfaces/menus/dropdownMenus/BaseDropdownMenuButtonItem";
import { z } from "zod";

export const SelectFilterOptionSchema = z.object({
  key: z.union([z.string(), z.number()]),
  label: z.string(),
  value: z.unknown(),
});

export interface SelectFilterOption extends z.infer<typeof SelectFilterOptionSchema> {}

/**
 * @todo add nested options
 */
export default defineComponent({
  name: "BaseFilterSelectOptionDropdownMenu",

  components: {
    BaseDropdownMenu,
  },

  props: {
    /**
     * Options for the filter.
     */
    options: {
      type: Array as PropType<SelectFilterOption[]>,
      required: true,
    },

    /**
     * Whether dropdown item text should not wrap
     */
    noWrap: {
      type: Boolean as PropType<boolean | undefined>,
      required: false,
      default: undefined,
    },
  },

  emits: {
    "update:modelValue": (_value: unknown) => true,
  },

  setup() {
    const { updateModelValue } = useModelValue();

    return {
      updateModelValue,
    };
  },

  computed: {
    dropdownItems(): BaseDropdownMenuButtonItem[] {
      return this.options.map(
        (option): BaseDropdownMenuButtonItem => ({
          label: option.label,
          type: "button" as const,
        })
      );
    },
  },

  methods: {
    handleOptionUpdate(_item: unknown, index: number) {
      this.updateModelValue(this.options[index]?.value);
    },
  },
});
</script>

<template>
  <svg
    class="animate-spin h-5 w-5"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-live="polite"
    data-testid="loading-spinner"
    role="alert"
  >
    <title v-if="accessibleName">{{ accessibleName }}</title>

    <circle
      aria-hidden="true"
      class="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      stroke-width="4"
    ></circle>

    <path aria-hidden="true" class="opacity-75" fill="currentColor" :d="svgPathD"></path>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseLoadingSpinner",
  props: {
    /** The accessible name for the spinner. */
    accessibleName: {
      type: String,
      required: false,
      default: undefined,
    },
  },

  data() {
    return {
      svgPathD:
        "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z",
    };
  },
});
</script>
